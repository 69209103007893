import { XIcon } from "lucide-react";
import React from "react";

interface BannerProps {
  title: string;
  visible?: boolean;
  onClose: () => void;
}

export default function Banner({ title, visible, onClose }: BannerProps) {
  return visible ? (
    <div className="absolute top-0 left-0 w-full bg-[rgba(31,41,55,0.9)] z-10">
      <div className="flex flex-row w-full h-full justify-center items-center gap-x-2 relative py-3 px-10">
        <button onClick={onClose} className="absolute top-1 right-1.5">
          <XIcon color="white" size={20} />
        </button>
        <h3 className="text-center">{title}</h3>
      </div>
    </div>
  ) : null;
}
