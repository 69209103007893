import { images } from "../../assets/images";
import { Message, Submit } from "../../hooks/use-chat";
import { UserIcon } from "@heroicons/react/20/solid";
import {
  RenderComponent,
  RenderComponents,
} from "./message-actions/render-component";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MessagesProps {
  chatId: string;
  setSubmitted: (submitted: boolean) => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  sendMessageHidden: (message: string) => Promise<void>;
  submit: (data: Submit) => void;
  messages: Message[];
  loading?: boolean;
  accessToken: string;
  currentTransaction: number | null;
  completeTransaction: (id: number) => void;
}

export default function Messages({
  chatId,
  setMessages,
  submit,
  sendMessageHidden,
  messages,
  loading,
  accessToken,
}: MessagesProps) {
  const [current, setCurrent] = useState<number>(0);
  const [processed, setProcessed] = useState<number>(0);
  const advanceCurrent = (startFrom?: number) => {
    const start = startFrom !== undefined ? startFrom : processed + 1;
    let newCurrent = messages.length;

    for (let i = start; i < messages.length; i++) {
      const message = messages[i];
      if (
        message.agent === "ui" &&
          message.component !== RenderComponents.TX_STATUS
      ) {
        newCurrent = i;
        break;
      }
    }

    setCurrent(newCurrent);
  };

  useEffect(() => {
    advanceCurrent();
  }, [messages.length]);

  return (
    <div className="max-h-[90vh] overflow-y-auto py-14 px-4">
      <div
        id="chat"
        className="flex-col w-full overflow-y-scroll pb-4 max-w-5xl mx-auto"
      >
        {messages.map((msg, index) => {
          const prevAgent =
            index > 0
              ? messages[index - 1].agent === "ui" ||
              messages[index - 1].agent === "ai"
              : false;
          const currAgent = msg.agent === "ui" || msg.agent === "ai";

          return (
            <div
              key={index}
              className={`flex flex-row ${
                prevAgent && !currAgent ? "my-10" : "mb-2"
              }`}
            >
              <div className="min-w-8">
                <div
                  className={`w-8 h-8 rounded-full flex justify-center items-center overflow-hidden ${
                    msg.agent === "human" ? "bg-blue-500" : "bg-green-500"
                  }`}
                >
                  {msg.agent === "human" ? (
                    <div>
                      <UserIcon className="w-6 h-6 text-white" />
                    </div>
                  ) : (
                    <img alt="pfp" src={images.BuilderSupport} />
                  )}
                </div>
              </div>
              <div className={`ml-3 max-w-screen-2xl w-full`}>
                <span className="text-[#f3f4f6]">
                  {msg.agent === "human" ? "You" : "Surface Area"}
                </span>
                <div className="text-gray-300 w-full">
                 
                  {msg.agent === "human" ? (
                    <pre className=" text-sm mt-1 break-words whitespace-pre-wrap">
                      {msg.message}
                    </pre>
                  ) : msg.agent === "ui" ? (
                    <RenderComponent
                      chatId={chatId}
                      submit={submit}
                      setMessages={setMessages}
                      sendMessageHidden={sendMessageHidden}
                      accessToken={accessToken}
                      component={msg.component ?? "-"}
                      data={msg.data}
                      active={current === index && index > processed}
                      onDone={() => {
                        setProcessed(index);
                        advanceCurrent(index + 1);
                      }}
                    />
                  ) : (
                    <pre className="break-words whitespace-pre-wrap">
                      <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: ({ node, ...props }) => (
                            <a
                              className="text-blue-600 underline"
                              {...props}
                              target="_blank"
                            >
                              {props.children}
                            </a>
                          ),
                        }}
                      >
                        {msg.message}
                      </Markdown>
                    </pre>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {loading && (
          <div className="flex flex-row mb-6">
            <div className="min-w-8">
              <div
                className={`w-8 h-8 rounded-full flex justify-center items-center overflow-hidden bg-green-500`}
              >
                <img alt="pfp" src={images.BuilderSupport} />
              </div>
            </div>
            <div className={`ml-3 max-w-screen-2xl`}>
              <span className="text-[#f3f4f6]">Surface Area</span>
              <div className="text-gray-300 mt-1 animate-pulse">Loading...</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
