import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { sample, random } from "lodash";

interface TokenConfettiProps {
  tokenUrl: string;
}

interface ConfettiPiece {
  x: number;
  y: number;
  rotation: number;
  width: number;
  height: number;
  image: string;
}

const TokenConfetti: React.FC<TokenConfettiProps> = ({ tokenUrl }) => {
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [pieces, setPieces] = useState<number>(200);

  const triggerConfetti = () => {
    setShowConfetti(true);
    setPieces(30); // Reset the number of pieces
    setTimeout(() => {
      // Gradually decrease the number of pieces for a fading effect
      const fadeOutInterval = setInterval(() => {
        setPieces((prev) => {
          if (prev <= 0) {
            clearInterval(fadeOutInterval);
            setShowConfetti(false);
            return 0;
          }
          return prev - 10; // Decrease pieces by 10 each interval
        });
      }, 100);
    }, 4000); // Start fading out after 4 seconds
  };

  const generateConfettiPieces = (): ConfettiPiece[] => {
    const pieces: ConfettiPiece[] = [];
    for (let i = 0; i < 30; i++) {
      pieces.push({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        rotation: random(0, 360),
        width: sample([20, 40, 60]) as number, // Random widths for depth effect
        height: sample([20, 40, 60]) as number,
        image: tokenUrl,
      });
    }
    return pieces;
  };

  useEffect(() => {
    if (!showConfetti) {
      setPieces(0);
    }
    triggerConfetti();
  }, [tokenUrl]);

  return (
    <div>
      <Confetti
        numberOfPieces={pieces}
        drawShape={(ctx) => {
          const piece = sample(generateConfettiPieces())!;
          const img = new Image();
          img.src = piece.image;
          ctx.save();
          ctx.rotate((piece.rotation * Math.PI) / 180);
          ctx.beginPath();
          ctx.arc(0, 0, piece.width / 2, 0, 2 * Math.PI); // Add roundness to the image
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(
            img,
            -piece.width / 2,
            -piece.height / 2,
            piece.width,
            piece.height
          );
          ctx.restore();
        }}
      />
    </div>
  );
};

export default TokenConfetti;
