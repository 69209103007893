import axios from "axios";

export const serverEndpoint = `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}`;
export const merchantDataEndpoint = `${serverEndpoint}/merchant`;
export const userDataEndpoint = `${serverEndpoint}/user`;

export const getDocumentData = async ({
  url,
  accessToken,
  idToken,
  searchParams,
}: {
  url: string;
  accessToken?: string;
  idToken?: string;
  searchParams?: { [key: string]: any };
}) => {
  // Create a URL object
  const urlObj = new URL(url);

  // Add search parameters if they exist
  if (searchParams) {
    Object.keys(searchParams).forEach((key) => {
      urlObj.searchParams.append(key, String(searchParams[key]));
    });
  }

  const response = await axios.get(urlObj.toString(), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "x-id-token": idToken,
    },
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const getProfileInfo = async (
  accessToken: string,
  idToken: string
): Promise<any> => {
  try {
    const userResponse = await getDocumentData({
      url: userDataEndpoint,
      accessToken,
      idToken,
    });

    const merchantResponse = await getOrCreateMerchant(accessToken, idToken);

    return { userData: userResponse.data, merchantData: merchantResponse };
  } catch (error: any) {
    if (error.message === "User not found") {
      throw "User not found";
    } else if (error?.response?.data?.error === "User not found") {
      throw "User not found";
    }
  }
};

const getOrCreateMerchant = async (accessToken: string, idToken: string) => {
  try {
    const response = await getDocumentData({
      url: merchantDataEndpoint,
      accessToken,
      idToken,
    });
    return response.data;
  } catch (e: any) {
    if (e?.response?.data?.error === "User is not a member of any merchant") {
      const createMerchantResponse = await postDocumentData({
        url: merchantDataEndpoint,
        data: {},
        accessToken,
        idToken,
      });
      if (createMerchantResponse?.data?.error) {
        throw new Error("Error creating merchant");
      }
      const merchantResponse = await getDocumentData({
        url: merchantDataEndpoint,
        accessToken,
        idToken,
      });
      return merchantResponse.data;
    } else throw e;
  }
};

export const postDocumentData = async ({
  url,
  data,
  accessToken,
  idToken,
}: {
  url: string;
  data: { [key: string]: any };
  accessToken: string;
  idToken: string;
}) => {
  const response = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "x-id-token": idToken,
    },
  });

  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};
