import { ShieldExclamationIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import Modal from "../themed/Modal";

interface LockPeriodProps {
  chains: string[];
}

// This is an object to store the lock time period for chains
const lockChainPeriods: { [name: string]: number } = {
  DEGEN: 4,
};

const LockPeriod = ({ chains }: LockPeriodProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [chain, setChain] = useState<string>("");

  useEffect(() => {
    const lockChains = Object.keys(lockChainPeriods);
    const originChainLock = lockChains.includes(chains[0]);
    const destChainLock = lockChains.includes(chains[1]);

    if (originChainLock || destChainLock) setVisible(true);

    if (originChainLock) setChain(chains[0]);
    if (destChainLock) setChain(chains[1]);
  }, [chains]);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <div className="flex flex-col justify-center items-center mb-10">
        <ShieldExclamationIcon width={75} />
        <h1 className="text-2xl mt-1">¡ Warning !</h1>
      </div>
      <div className="flex flex-col text-center w-full px-6 gap-1 break-words">
        <p>{`Your transaction involves ${chain} chain.`}</p>
        <p>
          {`This chain has a lock time period of ${lockChainPeriods[chain]} days.`}
        </p>
        <p>You won't be able to withdraw until that time has passed.</p>
      </div>
    </Modal>
  );
};

export default LockPeriod;
