import React, { useState } from "react";
import images from "../../../assets/images/brand";

const NFTsView: React.FC<{ data: { nfts: any[] } }> = ({
  data,
}): React.ReactElement => {
  const [nfts, setNfts] = useState(data.nfts.flat());

  return (
    <div className="flex flex-row gap-x-5 gap-y-2 flex-wrap justify-center items-center">
      {nfts
        .filter((nft) => nft !== null)
        .map((nft: any, index: number) => (
          <div key={index}>
            <img
              src={nft.image ?? images.SphereOneLogoFull}
              alt={nft.name}
              className="w-64 rounded"
            />
          </div>
        ))}
    </div>
  );
};

export default NFTsView;
