import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import priceIndicator from "highcharts/modules/price-indicator";
import HighContrastDark from "highcharts/themes/high-contrast-dark";
import axios from "axios";

// Initialize the corresponding modules
priceIndicator(Highcharts);
HighContrastDark(Highcharts);

export const TokenChart = ({
  data: tokenDetails,
}: {
  data: {
    id: string;
    symbol: string;
    name: string;
  };
}) => {
  const [chart, setChart] = useState<Highcharts.Options | null>(null);
  const [interval, setInterval] = useState<"1h" | "3h">("1h");
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CREATOR_ENDPOINT}/chart/${tokenDetails.id}/${interval}`
        );
        setChart({
          rangeSelector: {
            allButtonsEnabled: true,
            buttons: [
              {
                type: "day",
                count: 1,
                text: "1d",
                events: {
                  click: function () {
                    if (interval === "1h") return;
                    setInterval("1h");
                    setSelected(0);
                  },
                },
              },
              {
                type: "week",
                count: 1,
                text: "1w",
                events: {
                  click: function () {
                    if (interval === "1h") return;
                    setInterval("1h");
                    setSelected(1);
                  },
                },
              },
              {
                type: "month",
                count: 1,
                text: "1m",
                events: {
                  click: function () {
                    if (interval === "3h") return;
                    setInterval("3h");
                    setSelected(2);
                  },
                },
              },
            ],
            selected: selected,
          },
          navigator: {
            series: {
              color: Highcharts.getOptions().colors?.[0],
            },
          },
          series: [
            {
              type: "candlestick",
              name: `${tokenDetails.symbol}/USD`,
              data: response.data,
              color: "#FF0000",
              upColor: "#00FF00",
              upLineColor: "#00FF00",
              lineColor: "#FF0000",
            },
          ],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [interval]);

  return (
    <div className="flex h-96 w-full mt-8">
      {!loading ? (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={chart}
        />
      ) : (
        <ChartSkeleton />
      )}
    </div>
  );
};
const ChartSkeleton = () => {
  return (
    <div className="animate-pulse h-full w-full">
      <div className="flex min-h-80 sm:min-h-96 max-h-96 max-w-[600px] w-full bg-[#1f1f20]"></div>
    </div>
  );
};
