import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { AllowancePreference, usePreferences } from "../hooks/use-preferences";
import { GptModelSelect } from "./chat/gpt-model-select";

export default function PreferencesPopover() {
  const { preferences, updatePreferences } = usePreferences();

  function handleSlippageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    if (value > 0 && value < 20) {
      updatePreferences({ slippage: value });
    }
  }

  function handleCustomApiKeyChange(e: React.ChangeEvent<HTMLInputElement>) {
    updatePreferences({ customApiKey: e.target.value });
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button>
          <AdjustmentsHorizontalIcon className="h-6 w-6 text-white" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-full">
        <div className="grid gap-4">
          <div>
            <h4 className="font-medium leading-none">Preferences</h4>
          </div>
          <div className="grid gap-2 space-y-2">
            <div className="grid grid-cols-3 items-center gap-4">
              <Label>Model</Label>
              <div className="col-span-2 h-8">
                <GptModelSelect />
              </div>
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label>Custom Api Key</Label>
              <Input
                id="custom_api_key"
                defaultValue={preferences.customApiKey}
                className="col-span-2 h-8"
                type="text"
                onChange={handleCustomApiKeyChange}
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="slippage">Slippage (%)</Label>
              <Input
                id="slippage"
                defaultValue={preferences.slippage}
                className="col-span-2 h-8"
                type="number"
                onChange={handleSlippageChange}
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-4 max-w-full">
              <Label>Allowance</Label>
              <RadioGroup
                defaultValue={preferences.allowance}
                className="col-span-2 grid grid-cols-2"
                onValueChange={(value: AllowancePreference) =>
                  updatePreferences({ allowance: value })
                }
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value={AllowancePreference.EXACT}
                    id={AllowancePreference.EXACT}
                  />
                  <Label htmlFor={AllowancePreference.EXACT}>Exact</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value={AllowancePreference.UNLIMITED}
                    id={AllowancePreference.UNLIMITED}
                  />
                  <Label htmlFor={AllowancePreference.UNLIMITED}>
                    Unlimited
                  </Label>
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
