import { createContext, useContext } from "react";
import { WalletProvider, WalletSignerContextProvider } from "./wallet-provider";
import { VersionedMessage, VersionedTransaction } from "@solana/web3.js";
import { AssembledTransaction, SolanaTransactionData } from "../use-wallets";
import { Buffer } from "buffer";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PhantomWalletName } from "@solana/wallet-adapter-wallets";
import { GoogleViaTipLinkWalletName } from "@tiplink/wallet-adapter";

const SolanaWalletContext = createContext<
  WalletSignerContextProvider | undefined
>(undefined);

export const useSolanaWallets = () => {
  const context = useContext(SolanaWalletContext);
  if (!context) {
    throw new Error(
      "useSolanaWallets must be used within a SolanaWalletContext"
    );
  }
  return context;
};

export const SolanaWalletProvider = ({ children }: { children: any }) => {
  const { connection } = useConnection();
  const solWallet = useWallet();

  async function connect(provider: WalletProvider): Promise<void> {
    if (provider === WalletProvider.PHANTOM) {
      solWallet.select(PhantomWalletName);
    } else if (provider === WalletProvider.TIPLINK) {
      solWallet.select(GoogleViaTipLinkWalletName);
    } else {
      throw new Error("Unsupported provider");
    }
  }

  function disconnect() {
    return solWallet.disconnect();
  }

  async function signAndSendTransaction(
    tx: AssembledTransaction
  ): Promise<string> {
    tx = tx as SolanaTransactionData;
    if (tx.serializedTransaction) {
      const bufferized = Buffer.from(tx.serializedTransaction, "base64");
      const versionedTransaction = VersionedTransaction.deserialize(bufferized);

      const signature = await solWallet.sendTransaction(
        versionedTransaction,
        connection
      );
      return signature;
    } else if (tx.serializedTransactionMessage) {
      const bufferized = Buffer.from(tx.serializedTransactionMessage, "base64");
      const versionedMessage = VersionedMessage.deserialize(bufferized);
      const versionedTransaction = new VersionedTransaction(versionedMessage);

      const signature = await solWallet.sendTransaction(
        versionedTransaction,
        connection
      );

      return signature;
    } else {
      throw new Error("Serialized transaction is required");
    }
  }

  async function signMessage(message: string): Promise<Uint8Array> {
    if (!solWallet.signMessage) {
      throw new Error("signMessage is not supported");
    }
    const encodedMessage = new TextEncoder().encode(message);
    const signature = await solWallet.signMessage(encodedMessage);
    return signature;
  }

  return (
    <SolanaWalletContext.Provider
      value={{
        authSig: null,
        agentsPreference: false,
        setAgentsPreference: () => {},
        address: solWallet.publicKey?.toBase58() ?? null,
        connect,
        disconnect,
        signAndSendTransaction,
        signMessage,
      }}
    >
      {children}
    </SolanaWalletContext.Provider>
  );
};
