import { useState, useEffect, createContext, useContext } from "react";
import { GptModel } from "../services/server";

export enum AllowancePreference {
  EXACT = "exact",
  UNLIMITED = "unlimited",
}

export interface Preferences {
  slippage: number;
  allowance: AllowancePreference;
  gptModel: GptModel | null;
  customApiKey: string;
}

const defaultPreferences: Preferences = {
  slippage: 1,
  allowance: AllowancePreference.EXACT,
  gptModel: null,
  customApiKey: "",
};

interface PreferencesContextType {
  preferences: Preferences;
  updatePreferences: (newPreferences: Partial<Preferences>) => void;
  isLoaded: boolean;
}

const PreferencesContext = createContext<PreferencesContextType | undefined>(
  undefined
);

export function PreferencesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [preferences, setPreferences] =
    useState<Preferences>(defaultPreferences);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const storedPreferences = localStorage.getItem("preferences");
    if (storedPreferences) {
      try {
        setPreferences(JSON.parse(storedPreferences) as Preferences);
      } catch (error) {
        console.error("Error parsing preferences:", error);
      }
    }
    setIsLoaded(true);
  }, []);

  const updatePreferences = (newPreferences: Partial<Preferences>) => {
    const updatedPreferences = { ...preferences, ...newPreferences };
    setPreferences(updatedPreferences);
    localStorage.setItem("preferences", JSON.stringify(updatedPreferences));
  };

  return (
    <PreferencesContext.Provider
      value={{ preferences, updatePreferences, isLoaded }}
    >
      {children}
    </PreferencesContext.Provider>
  );
}

export function usePreferences() {
  const context = useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error("usePreferences must be used within a PreferencesProvider");
  }
  return context;
}
