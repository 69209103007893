import { createContext, useContext, useState } from "react";
import { WalletProvider, WalletSignerContextProvider } from "./wallet-provider";
import { AssembledTransaction, BitcoinTransactionData } from "../use-wallets";
import axios from "axios";
import { Buffer } from "buffer";
import { Psbt, networks, script, opcodes } from "bitcoinjs-lib";

interface BitcoinFees {
  fastestFee: number;
  halfHourFee: number;
  hourFee: number;
  economyFee: number;
  minimumFee: number;
}

const BitcoinWalletContext = createContext<
  WalletSignerContextProvider | undefined
>(undefined);

export const useBitcoinWallets = () => {
  const context = useContext(BitcoinWalletContext);
  if (!context) {
    throw new Error(
      "useBitcoinWallets must be used within a BitcoinWalletContext"
    );
  }
  return context;
};

export const BitcoinWalletProvider = ({ children }: { children: any }) => {
  const [address, setAddress] = useState<string | null>(null);

  async function connect(provider: WalletProvider | any): Promise<void> {
    let phantomProvider: any;
    if ("phantom" in window) {
      const { phantom }: any = window;
      phantomProvider = phantom.bitcoin;
    } else {
      window.open("https://phantom.app/", "_blank");
      return;
    }

    try {
      const accounts = await phantomProvider.requestAccounts();
      if (accounts && accounts.length > 0) {
        const paymentAccount = accounts.find(
          (account: any) => account.purpose === "payment"
        );

        if (paymentAccount) {
          setAddress(paymentAccount.address);
        } else {
          throw new Error("No payment address returned from Phantom wallet");
        }
      } else {
        throw new Error("No Bitcoin address returned from Phantom wallet");
      }
    } catch (error) {
      throw new Error("Error connecting to Bitcoin wallet");
    }
  }

  async function disconnect() {
    setAddress(null);
  }

  async function signAndSendTransaction(
    tx: AssembledTransaction
  ): Promise<string> {
    if (!address) {
      throw new Error("Wallet not connected");
    }

    const { phantom }: any = window;
    const phantomProvider = phantom.bitcoin;

    if (isBitcoinTransaction(tx)) {
      try {
        const utxos = await getUTXOs(address);
        if (utxos.length === 0) {
          throw new Error("No UTXOs available to fund the transaction");
        }

        // Starts creating the transaction
        const psbt = new Psbt({ network: networks.bitcoin });

        for (const utxo of utxos) {
          const rawTx = await axios.get(
            `https://blockstream.info/api/tx/${utxo.txid}/hex`
          );

          psbt.addInput({
            hash: utxo.txid,
            index: utxo.vout,
            nonWitnessUtxo: Buffer.from(rawTx.data, "hex"),
          });
        }

        psbt.addOutput({
          address: tx.toBitcoinAddress,
          value: Number(tx.amount),
        });

        if (tx?.memo) {
          psbt.addOutput({
            script: script.compile([
              opcodes.OP_RETURN,
              Buffer.from(tx?.memo, "utf8"),
            ]),
            value: 0,
          });
        }

        const totalInputValue = utxos.reduce(
          (acc, utxo) => acc + utxo.value,
          0
        );

        // Get the recommended fee
        const feeRates = await getBitcoinRecommendedFees();
        const fastestFee = feeRates.fastestFee;

        // Calculate the fee based on the transaction size (estimate 180 bytes per input and 34 bytes per output plus 10 for base transaction size
        const estimatedSize =
          psbt.inputCount * 180 + psbt.data.outputs.length * 34 + 10;
        const fee = fastestFee * estimatedSize;

        const changeAmount = totalInputValue - Number(tx.amount) - fee;

        if (changeAmount > 546) {
          // 546 is the minimum amount of sats that can be sent to an address (dust limit)
          psbt.addOutput({
            address: address,
            value: changeAmount,
          });
        }

        const signedPSBTBytes = await phantomProvider.signPSBT(
          psbt.toBuffer(),
          {
            inputsToSign: [
              {
                address: address,
                signingIndexes: utxos.map((_, index) => index),
              },
            ],
          }
        );

        // Creates a signed PSBT
        const signedPsbt = Psbt.fromBuffer(Buffer.from(signedPSBTBytes));

        // Finalize and extract the transaction
        signedPsbt.finalizeAllInputs();
        const transaction = signedPsbt.extractTransaction();
        const txHex = transaction.toHex();

        const txId = await broadcastTransaction(txHex);
        return txId;
      } catch (err: any) {
        if (err && err.message) {
          throw new Error(err.message);
        } else if (err && err.error && err.error.message) {
          throw new Error(err.error.message);
        } else {
          throw new Error("An unexpected error occurred");
        }
      }
    } else {
      throw new Error("Invalid transaction type for Bitcoin");
    }
  }

  async function signMessage(message: string): Promise<string> {
    if (!address) {
      throw new Error("Wallet not connected");
    }

    const { phantom }: any = window;
    const phantomProvider = phantom.bitcoin;

    try {
      const encodedMessage = new TextEncoder().encode(message);
      const { signature } = await phantomProvider.signMessage(
        address,
        encodedMessage
      );
      return Buffer.from(signature).toString("hex");
    } catch (error) {
      throw new Error("Failed to sign message");
    }
  }

  async function getUTXOs(address: string): Promise<any[]> {
    try {
      const response = await axios.get(
        `https://blockstream.info/api/address/${address}/utxo`
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch UTXOs");
    }
  }

  const getBitcoinRecommendedFees = async (): Promise<BitcoinFees> => {
    const response = await axios.get(
      "https://mempool.space/api/v1/fees/recommended"
    );
    return response.data as BitcoinFees;
  };

  async function broadcastTransaction(txHex: string): Promise<string> {
    try {
      const result = await axios({
        method: "POST",
        url: `https://blockstream.info/api/tx`,
        data: txHex,
      });
      return result.data;
    } catch (error: any) {
      throw new Error(`Failed to broadcast Bitcointransaction: ${error}`);
    }
  }

  function isBitcoinTransaction(
    tx: AssembledTransaction
  ): tx is BitcoinTransactionData {
    return (
      (tx as BitcoinTransactionData).toBitcoinAddress !== undefined &&
      (tx as BitcoinTransactionData).amount !== undefined
    );
  }
  return (
    <BitcoinWalletContext.Provider
      value={{
        authSig: null,
        address,
        connect,
        disconnect,
        signAndSendTransaction,
        signMessage,
        agentsPreference: false,
        setAgentsPreference: () => {},
      }}
    >
      {children}
    </BitcoinWalletContext.Provider>
  );
};
