import ConnectCube from "./Connect.svg";
import Cubes from "./Cubes.svg";
import SphereLogo from "./SphereIconLogoGreen.png";
import BuilderVector from "./builder-vector.png";
import DefaultCryptoLogo from "./DefaultCryptoLogo.png";

export const images = {
  ConnectCube: ConnectCube,
  Cubes: Cubes,
  SphereLogo: SphereLogo,
  BuilderSupport: BuilderVector,
  DefaultCryptoLogo: DefaultCryptoLogo,
  DefaultAgentLogo: "https://firebasestorage.googleapis.com/v0/b/sphereone-testing.appspot.com/o/Frame-9.png?alt=media&token=9c05b74d-9948-445f-936d-18f253b61b75"
};
