import { useEffect, useState } from "react";
import { FaChevronLeft, FaExclamationCircle } from "react-icons/fa";

import { images } from "../../assets/images";
import { useChat } from "../../hooks/use-chat";
import { AgentProps, AgentSupportedActions } from "../agent-functionality";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectPreferredAgent } from "../../redux/slices/gpt";
import { XMarkIcon } from "@heroicons/react/20/solid";
import HorizontalSlider from "./message-actions/QuickActionSlider";

export default function ChatEmptyScreen(props: {
  setInput: (str: string) => void;
  chatStarted: boolean;
}) {
  const { availableAgents: agents, superAgent, allChains } = useChat();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { preferredAgent } = useAppSelector((state) => state.gpt);
  const [filteredAgents, setFilteredAgents] = useState<AgentProps[]>(agents);
  const [filters, setFilters] = useState<{
    action?: string;
    chains: string[];
    prompt?: string;
  }>({
    action: "ALL",
    chains: [],
    prompt: undefined,
  });

  const dispatch = useAppDispatch();

  function setSelectedAgent(agent: AgentProps | null) {
    dispatch(selectPreferredAgent(agent));
  }

  // region Search/Filter Agents
  const onChangeFilter = (
    key: "action" | "chains" | "prompt",
    value: string | string[],
  ) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  useEffect(() => {
    let currentAgents: AgentProps[] = agents;
    if (filters.chains.length > 0) {
      currentAgents = currentAgents.filter((agent) =>
        filters.chains.some((chain) => agent.supportedChains.includes(chain)),
      );
    }
    if (filters.action && filters.action !== "ALL") {
      currentAgents = currentAgents.filter(
        (agent) => agent.action === filters.action,
      );
    }
    if (filters.prompt) {
      currentAgents = currentAgents.filter(
        (agent) =>
          agent.action.toLowerCase().includes(filters.prompt!.toLowerCase()) ||
          agent.name.toLowerCase().includes(filters.prompt!.toLowerCase()) ||
          agent.parent.toLowerCase().includes(filters.prompt!.toLowerCase()),
      );
    }
    setFilteredAgents(currentAgents);
  }, [filters, agents]);

  const isAlreadySelected = (chain: string): boolean =>
    filters.chains.some((filterChain: string) => filterChain === chain);
  const addChainSelection = (chain: string) => {
    // add it
    onChangeFilter("chains", [...filters.chains, chain]);
  };
  const removeChainSelection = (chain: string) => {
    // remove it
    onChangeFilter(
      "chains",
      filters.chains.filter((filterChain: string) => filterChain !== chain),
    );
  };
  // endregion

  return (
    <div className="max-w-5xl mx-auto p-4 text-center -z-50 flex flex-col h-full">
      {showModal && (
        <div className="absolute top-0 right-0 bg-gray-900 bg-opacity-80 z-50 flex items-center justify-center flex-col h-dvh w-dvw">
          <div className="flex items-center bg-gray-900 content-center flex-col rounded sm:max-w-1/2  sm:max-w-1/2 w-full sm:h-1/2 sm:w-1/2 p-8 gap-5">
            <div className="flex w-full py-2">
              <span className="flex flex-1 text-2xl">Supported chains</span>
              <XMarkIcon
                onClick={() => setShowModal(false)}
                className="h-8 w-auto text-white cursor-pointer text-2xl hover:bg-gray-500 hover:rounded"
              />
            </div>
            <div className="mx-auto overflow-scroll flex flex-wrap gap-4">
              {allChains.map((chain: any) => (
                <div
                  className={`flex rounded-lg p-2 gap-3 items-center hover:cursor-pointer hover:bg-gray-500 ${isAlreadySelected(chain.name) ? "bg-gray-400" : "bg-gray-700"}`}
                  onClick={() => {
                    if (!isAlreadySelected(chain.name)) {
                      addChainSelection(chain.name);
                    } else {
                      removeChainSelection(chain.name);
                    }
                  }}
                >
                  <img
                    src={chain.image ? chain.image : images.DefaultCryptoLogo}
                    className="w-4 h-4 rounded-full"
                    alt={chain.name}
                    title={chain.name}
                  />
                  <span className="text-lg">{chain.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="h-full">
        {preferredAgent === null ? (
          <>
            <img
              src={images.SphereLogo}
              alt="Sphere logo"
              className="h-20 w-20 rounded-full mx-auto mb-2"
            />
            <h1 className="text-2xl text-gray-300 p-2">Explore agents</h1>

            <div className="container mx-auto mt-2 py-4">
              <h1 className="text-2xl font-bold">Quick Actions</h1>
              <HorizontalSlider />
            </div>
          </>
        ) : (
          <>
            <img
              src={preferredAgent.img ?? images.DefaultAgentLogo}
              alt="Sphere logo"
              className="h-20 w-20 rounded-full mx-auto mb-2"
            />
            <h1 className="text-4xl text-gray-300 p-2">
              {preferredAgent.name}
            </h1>
          </>
        )}

        {preferredAgent ? (
          <button
            className="rounded cursor-pointer hover:cursor-pointer bg-gray-700 p-4 flex items-center justify-center gap-2"
            onClick={() => {
              setSelectedAgent(null);
            }}
          >
            <FaChevronLeft /> Back
          </button>
        ) : (
          <div className="flex flex-col gap-y-3">
            <div className="w-full mt-2">
              <input
                value={filters.prompt}
                className="w-full py-1 px-2 bg-gray-800 rounded-lg border border-gray-600"
                placeholder="Search Agent..."
                onChange={(e) => onChangeFilter("prompt", e.target.value)}
              />
            </div>
            <div>
              {allChains?.length > 0 && (
                <div className="flex justify-center gap-2">
                  {allChains.slice(0, 5).map((chain: any) => (
                    <div
                      key={chain.name}
                      className={`p-2 rounded-lg hover:cursor-pointer hover:bg-gray-500 ${isAlreadySelected(chain.name) ? "bg-gray-400" : "bg-gray-700"}`}
                      onClick={() => {
                        if (!isAlreadySelected(chain.name)) {
                          addChainSelection(chain.name);
                        } else {
                          removeChainSelection(chain.name);
                        }
                      }}
                    >
                      <img
                        src={
                          chain.image ? chain.image : images.DefaultCryptoLogo
                        }
                        className="w-6 h-6"
                        alt={chain.name}
                        title={chain.name}
                      />
                    </div>
                  ))}
                  {allChains.length > 5 && (
                    <div
                      className="bg-gray-700 p-2 rounded-lg cursor-pointer hover:bg-gray-500"
                      onClick={() => setShowModal(true)}
                    >
                      <span className="text-gray-300">
                        +{allChains.length - 5}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            {filters.chains.length > 0 && (
              <div className="flex flex-wrap gap-x-2 gap-y-1">
                {filters.chains.map((chain) => (
                  <div
                    className="flex flex-row bg-gray-700 hover:bg-gray-500 text-white rounded-lg p-2 cursor-pointer"
                    onClick={() => removeChainSelection(chain)}
                  >
                    <p className="mr-2 text-sm">{chain}</p>
                    <XMarkIcon className="h-5 w-auto" />
                  </div>
                ))}
              </div>
            )}
            <div className="flex flex-row flex-wrap justify-between gap-x-1">
              <div
                onClick={() => onChangeFilter("action", "ALL")}
                className={`${
                  filters.action === "ALL"
                    ? "border-white"
                    : "hover:border-white"
                } border-b border-transparent hover:cursor-pointer`}
              >
                <span className="text-sm">ALL</span>
              </div>
              {Object.values(AgentSupportedActions).map(
                (action: string, index: number) => (
                  <div
                    className={`${
                      filters.action === action
                        ? "border-white"
                        : "hover:border-white"
                    } border-b border-transparent hover:cursor-pointer`}
                    key={index}
                    onClick={() => onChangeFilter("action", action)}
                  >
                    <span className="text-sm">{action}</span>
                  </div>
                ),
              )}
            </div>
          </div>
        )}
      </div>

      {!preferredAgent && (
          <div className="flex mt-3 flex-col gap-y-1 h-full">
            {superAgent && (
              <div
                className="flex flex-row items-center bg-gray-700 border-2 border-green-300 rounded-lg p-6 shadow-lg text-gray-300 hover:bg-gray-500 hover:text-green-200 cursor-pointer"
                onClick={() => {
                  setSelectedAgent(superAgent);
                }}
              >
                <img
                  className="h-24 w-auto rounded-full"
                  src={superAgent.img ?? images.SphereLogo}
                  alt={superAgent.name}
                />
                <div className="pl-6 text-left">
                  <h1 className="font-bold text-2xl">{superAgent.name}</h1>
                  <h4 className="text-base font-medium">
                    {superAgent.description}
                  </h4>
                </div>
              </div>
            )}
            <div className="flex flex-grow relative h-full">
              <div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-b from-background to-transparent z-5" />
              <div className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-t from-background to-transparent z-5" />
              <Grid agents={filteredAgents} setSelectedAgent={setSelectedAgent} />
            </div>
          </div>
      )}
    </div>
  );
}

const Grid = ({
  agents,
  setSelectedAgent,
}: {
  agents: AgentProps[];
  setSelectedAgent: any;
}) => {
  return (
    <div className="py-4 flex justify-center gap-2 overflow-auto h-full w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-min w-full gap-4">
        {agents.length > 0 ? (
          agents.map((agent: AgentProps, index: number) => (
            <div
              key={index}
              className="hover:bg-gray-800 transition-all duration-200 hover:cursor-pointer cursor-pointer bg-gray-700 items-center flex flex-row rounded p-4 w-full"
              onClick={() => {
                setSelectedAgent(agent);
              }}
            >
              <img
                className="h-20 w-auto rounded-lg"
                src={agent.img ? agent.img : images.DefaultAgentLogo}
                alt={agent.name}
                loading={"lazy"}
              />
              <div className="p-4 text-left">
                <h1 className="font-semibold text-xl">{agent.name}</h1>
                <h3 className="text-gray-400">
                  Operation:{" "}
                  <b className="text-white">{agent.action}</b>
                </h3>
                {agent.description && (
                  <h3 className="text-gray-400 flex flex-row gap-x-2">
                    Description:{" "}
                    <p className="text-gray-100 line-clamp-2 hover:line-clamp-none">
                      {agent.description}
                    </p>
                  </h3>
                )}
                <h3 className="text-gray-400">
                  Parent: <b className="text-white">{agent.parent}</b>
                </h3>
              </div>
            </div>
          ))
        ) : (
          <div className="transition-all duration-200 bg-gray-700 items-center flex flex-1 rounded p-4 space-x-2">
            <FaExclamationCircle className="h-10 w-auto" />
            <p>No Matches. Please try a different search.</p>
          </div>
        )}
      </div>
    </div>
  );
};
