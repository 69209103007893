import { Bars4Icon } from "@heroicons/react/20/solid";
import ConnectWalletButton from "./connect-wallet-button";
import PreferencesPopover from "./preferences-popover";
import { BalancesDropdown } from "./chat/balances";
import { useState } from "react";
import { useChat } from "../hooks/use-chat";

export default function Header(props: {
  toggleSidebar: () => void;
  chatStarted: boolean;
}) {
  const [showBalances, setShowBalances] = useState(false);
  const { messages, setMessages } = useChat();

  return (
    <div className="h-14 px-4 border-b border-b-gray-700 flex justify-between">
      <div className="flex space-x-4 items-center">
        <h1 className="text-lg text-white text-nowrap">Surface Area</h1>
        {messages.length > 1 && (
          <button
            className="rounded cursor-pointer hover:cursor-pointer bg-gray-700 flex items-center justify-center p-2"
            onClick={() => {
              setMessages([]);
            }}
          >
            Start a new conversation
          </button>
        )}
      </div>

      <div className="flex space-x-4 items-center">
        <div className="hidden sm:flex gap-3 items-center">
          <a
            className="text-xs sm:text-sm text-white text-nowrap underline"
            href="https://sphereone.notion.site/Surface-Area-d4883498ae5f41909e7fae69795645ad"
            target="_blank"
            rel="noreferrer"
          >
            Docs
          </a>
        </div>
        <div className="flex space-x-2 items-center">
          <ConnectWalletButton chatStarted={props.chatStarted} />
          <PreferencesPopover />
        </div>

        <button onClick={() => setShowBalances(!showBalances)}>
          <Bars4Icon className="h-6 w-6 text-white" />
        </button>
        {showBalances && (
          <div className="fixed top-10 right-4 z-20 transition-all ease-in-out duration-300">
            <BalancesDropdown />
          </div>
        )}
      </div>
    </div>
  );
}
