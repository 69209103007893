import React from "react";

const QuickActionCard = ({
  quickAction,
  onClick,
}: {
  quickAction: string;
  onClick: (quickAction: string) => void;
}) => {
  return (
    <div
      className="min-w-72 min-h-16 bg-gray-700 hover:bg-gray-400 shadow-md rounded-lg px-4 cursor-pointer hover:shadow-lg transition-shadow duration-300 flex justify-center align-middle items-center"
      onClick={() => onClick(quickAction)}
    >
      <p className="text-gray-300 font-medium text-sm flex justify-center align-middle items-center">
        {quickAction}
      </p>
    </div>
  );
};

export default QuickActionCard;
