import React, { useRef, useMemo } from "react";
import QuickActionCard from "./QuickActionCard";
import { AgentProps } from "../../agent-functionality";
import { useChat } from "../../../hooks/use-chat";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const shuffleArray = (array: AgentProps[]) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const QuickActionSlider = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { availableAgents: agents, appendMessage } = useChat();

  const shuffledAgents = useMemo(() => {
    return shuffleArray(agents);
  }, [agents]);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -450, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 450, behavior: "smooth" });
    }
  };

  return (
    <div className="relative w-full">
      <button
        className="hidden sm:flex absolute left-0 top-1/2 transform -translate-y-1/2 hover:cursor-pointer bg-transparent hover:bg-blue-500 border-gray-300 border-2 p-4 items-center justify-center gap-2 rounded cursor-pointer"
        onClick={scrollLeft}
      >
        <FaChevronLeft />
      </button>

      <div
        ref={sliderRef}
        className="flex overflow-x-scroll scroll-smooth scrollbar-hide space-x-4 py-4 mx-16"
      >
        {shuffledAgents.map((agent: AgentProps, index: number) =>
          agent.examples.map((example, idx) => (
            <QuickActionCard
              key={`${index}-${idx}`}
              quickAction={example}
              onClick={(example: string) => {
                appendMessage(example.concat(" ", agent.name ?? ""));
              }}
            />
          ))
        )}
      </div>

      <button
        className="hidden sm:flex absolute right-0 top-1/2 transform -translate-y-1/2 hover:cursor-pointer bg-transparent hover:bg-blue-500 border-gray-300 border-2 p-4 items-center justify-center gap-2 rounded cursor-pointer"
        onClick={scrollRight}
      >
        <FaChevronRight />
      </button>
    </div>
  );
};

export default QuickActionSlider;
