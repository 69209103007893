import { useState } from "react";
import { WalletType, useWallets } from "../../../hooks/wallets/use-wallets";
import { serverApi } from "../../../services/server";

interface IFundTipLink {
  tiplink_url: string;
  serialized_transaction_message: string;
  to_email: string;
  amount: string;
  token: {
    address: string;
    symbol: string;
    decimals: string;
    logoURI: string;
    amount: string;
  };
}

export const FundTipLink = ({
  data,
  active,
  onDone,
}: {
  data: IFundTipLink;
  active: boolean;
  onDone: () => void;
}) => {
  const { signTransactions } = useWallets();
  const [loading, setLoading] = useState<boolean>(false);
  const [funded, setFunded] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const onFundTipLink = async () => {
    if (funded) return;

    try {
      setLoading(true);
      const res = await signTransactions(
        [{ serializedTransactionMessage: data.serialized_transaction_message }],
        WalletType.SOLANA
      );
      if (res.error !== null) {
        throw new Error(res.error);
      }

      window.alert(
        "Wallet funded successfully, the recipient can already withdraw"
      );
      setFunded(true);
      onDone();
    } catch (e: any) {
      console.error(e);
      window.alert("Error: " + e.message ?? "Something went wrong");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onSendTipLink = async () => {
    if (sent) return;

    try {
      setLoading(true);

      await serverApi.sendTipLink(data.tiplink_url, data.to_email);

      // send tip link
      window.alert("Tip link sent successfully");
      setSent(true);
    } catch (e: any) {
      console.error(e);
      window.alert("Error: " + e.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[550px] bg-gray-800 border border-gray-700 shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4 min-w-full sm:min-w-[450px]">
      <div className="bg-gray-700 border border-gray-600 rounded-lg p-3 flex justify-between items-center gap-4 w-full h-70px">
        {/* Input Token */}
        <div className="flex flex-row items-start gap-2">
          <img
            src={data?.token.logoURI}
            alt={data?.token.symbol}
            className="w-6 h-6 rounded-full"
          />
          <div className="flex flex-col justify-center gap-1">
            <span className="text-white font-medium text-sm leading-6">
              {data.amount} {data?.token.symbol}
            </span>
            <span className="text-gray-400 text-sm leading-6">
              to: {data.to_email}
            </span>
            <span className="text-gray-400 text-sm leading-6">
              link:{" "}
              <a href={data.tiplink_url} className="underline">
                {data.tiplink_url}
              </a>
            </span>
          </div>
        </div>
      </div>

      {funded === true && sent === true ? (
        <p>
          {data.to_email} should have an email with a claimable link. You can
          also share the link directly: {data.tiplink_url}
        </p>
      ) : (
        <div className="w-full grid-cols-2 grid gap-2">
          <button
            onClick={onDone}
            className={`w-full text-white text-sm bg-gray-500 hover:bg-gray-500/80 rounded-lg px-4 py-2 disabled:bg-gray-500/70 disabled:text-gray-300 ${
              loading ? "cursor-not-allowed animate-pulse" : ""
            }`}
            disabled={loading || !!funded || active === false}
          >
            Cancel
          </button>

          {funded === false ? (
            <button
              onClick={onFundTipLink}
              className={`w-full text-white text-sm bg-blue-500 hover:bg-blue-500/80 rounded-lg px-4 py-2 disabled:bg-blue-500/70 disabled:text-gray-300 ${
                loading ? "cursor-not-allowed animate-pulse" : ""
              }`}
              disabled={loading || !!funded || active === false}
            >
              {!loading ? "Fund wallet" : "Waiting for signature..."}
            </button>
          ) : (
            <button
              onClick={onSendTipLink}
              className={`w-full text-white text-sm bg-blue-500 hover:bg-blue-500/80 rounded-lg px-4 py-2 disabled:bg-blue-500/70 disabled:text-gray-300 ${
                loading ? "cursor-not-allowed animate-pulse" : ""
              }`}
              disabled={loading || !!sent}
            >
              {!loading ? "Send tip link" : "Sending..."}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
