import { XCircleIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { useState } from 'react';
import Countdown from 'react-countdown-simple'

enum TimeUnits {
  DAYS = "Days",
  HOURS = "Hours",
  MINUTES = "Minutes",
  SECONDS = "Seconds",

}

const TimeCountdown = ({ targetDate, setVisible }: {
  targetDate: string;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [position, setPosition] = useState({ x: 10, y: 20 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    const offsetX = e.clientX - position.x;
    const offsetY = e.clientY - position.y;
    setOffset({ x: offsetX, y: offsetY });
  };

  const handleMouseMove = (e: any) => {
    if (isDragging) {
      const newX = e.clientX - offset.x;
      const newY = e.clientY - offset.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const renderer = ({ days, hours, minutes, seconds }: any) =>
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        backgroundColor: 'red',
        cursor: 'move',
        zIndex: 999,
        display: 'inline-flex',
        padding: '42px',
        alignItems: 'flex-start',
        gap: '52px',
        borderRadius: '26px',
        border: '1px solid #FFF',
        background: '#1F1F1F',
        boxShadow: '0px 20px 54px 0px rgba(103, 65, 255, 0.32)',
      }}>
      <div className='flex flex-1 gap-10'>
        <button className='absolute top-2.5 right-3'>
          <XCircleIcon width={25} color='white' />
        </button>
        <CommonColumn timeUnits={TimeUnits.DAYS} remainingTime={days} />
        <CommonColumn timeUnits={TimeUnits.HOURS} remainingTime={hours} />
        <CommonColumn timeUnits={TimeUnits.MINUTES} remainingTime={minutes} />
        <CommonColumn timeUnits={TimeUnits.SECONDS} remainingTime={seconds} />
      </div>
    </div >

  return (
    <Countdown renderer={renderer} targetDate={targetDate} />
  )
}

const CommonColumn = ({ timeUnits, remainingTime }: { timeUnits: TimeUnits; remainingTime: number }) => {
  return <div className="flex flex-col items-center justify-center">
    <h1 style={{
      color: 'var(--text-text-invert, #FFF)',
      textAlign: 'center',
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '54.856px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '69.856px',
      letterSpacing: '0.699px',
    }}>{remainingTime}</h1>
    <h1 style={{
      color: 'var(--text-text-invert, #FFF)',
      textAlign: 'center',
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '15.013px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    }}>{timeUnits}</h1>
  </div>
}

export default TimeCountdown