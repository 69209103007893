import { createSlice } from "@reduxjs/toolkit";
import { AgentProps } from "../../components/agent-functionality";

export enum GPTFunctions {
  TRANSFER = "Transfer",
  SWAP = "Swap",
  BRIDGE = "Bridge",
}

export interface Message {
  from: string;
  message: string;
  title?: string;
  id: string;
  loading?: boolean;
}
export interface GPTState {
  name: string;
  conversationStarters: Array<string>;
  chat: Message[];
  capabilities: Array<{ value: GPTFunctions; active: boolean }>;
  preferredAgent: AgentProps | null;
}

const initialState: GPTState = {
  name: "Blockchain Integrator",
  conversationStarters: ["Integrate Polygon", "Integrate Avalanche"],
  chat: [],
  capabilities: [
    { value: GPTFunctions.TRANSFER, active: true },
    { value: GPTFunctions.SWAP, active: true },
    { value: GPTFunctions.BRIDGE, active: true },
  ],
  preferredAgent: null
};

const gptSlice = createSlice({
  name: "gpt",
  initialState,
  reducers: {
    setGptRedux: (state, { payload }: { payload: Partial<GPTState> }) => {
      state.name = payload.name ?? state.name;
      state.conversationStarters =
        payload.conversationStarters ?? state.conversationStarters;
      state.capabilities = payload.capabilities ?? state.capabilities;
    },
    addMessage: (state, { payload }: { payload: Message }) => {
      state.chat = state.chat?.length ? [...state.chat, payload] : [payload];
    },
    removeMessage: (state, action) => {
      state.chat = state.chat.filter((msg) => msg.id !== action.payload.id);
    },
    addConversationStarter: (state, action) => {
      state.conversationStarters.push(action.payload);
    },
    selectPreferredAgent: (state, { payload }: { payload: AgentProps | null }) => {
      state.preferredAgent = payload;
      if (payload !== null) {
        state.conversationStarters =
          payload.examples ?? state.conversationStarters;
      } else {
        state.conversationStarters = [];
      }
    }
  },
  extraReducers: (builder) => { },
});

export const {
  setGptRedux,
  addMessage,
  removeMessage,
  addConversationStarter,
  selectPreferredAgent
} = gptSlice.actions;
export default gptSlice.reducer;
