import { useEffect, useState } from "react";
import {
  InfoIcon,
  SlippageIcon,
  SwapIcon,
  SwapRoute,
  ClockIcon,
  OrderIcon,
} from "../../../assets/icons/flowbite-icons";
import { useAppDispatch } from "../../../redux/hooks";
import { Message, Submit } from "../../../hooks/use-chat";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  updateBalances,
  updateTokenConfetti,
} from "../../../redux/slices/user";
import SphereIconLogoGreen from "../../../assets/images/SphereIconLogoGreen.png";
import {
  AssembledTransaction,
  getWalletTypeFromTransaction,
  useWallets,
  WalletType,
} from "../../../hooks/wallets/use-wallets";
import { serverApi } from "../../../services/server";
import { AlertDialog } from "../../themed/alert-dialog";
import { images } from "../../../assets/images";

interface MesonMessage {
  chainId: number;
  hash_to_sign: string;
  encodedSwap: string;
}

interface QuoteResponse {
  from_token: {
    chainId: number;
    address: string;
    decimals: number;
    symbol: string;
    name: string;
    logoURI: string;
  };
  from_token_usd?: string;
  to_token: {
    chainId: number;
    address: string;
    decimals: number;
    symbol: string;
    name: string;
    logoURI: string;
  };
  to_token_usd?: string;
  from_amount: number;
  from_address: string;
  to_address: string;
  to_amount: number;
  from_chain: string;
  to_chain: string;
  estimated_time: number;
  gas_cost?: string;
  provider_fee?: string;
  slippage?: string;
  tool?: string;
  order?: string;
  agent: string;
  transactions: AssembledTransaction[];
  message_to_sign?: MesonMessage[];
  protocol_name?: string;
  bridge_id?: string; // Some Bridges Have and ID we can use to track the transaction on their explorer
}

const routePriorityTooltipContent = `
- RECOMMENDED: Balances cost and complexity. Sorts by cost, then ranks top 5% by ease of use.<br />
- FASTEST: Prioritizes shortest execution time for quickest transaction completion.<br />
- CHEAPEST: Minimizes transaction cost (token or USD minus gas cost) for the most economical option.<br />
- SAFEST: Emphasizes safety and reliability of routes, ranking by tool safety level and speed.
`;

const NATIVE_TOKEN_ADDRESSES = [
  "0x0000000000000000000000000000000000000000",
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
];

export function BridgeQuote({
  data,
  submit,
  onDone,
  sendMessageHidden,
}: {
  data: QuoteResponse;
  submit: (data: Submit) => void;
  sendMessageHidden: (message: string) => void;
  active: boolean;
  onDone: () => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}) {
  const dispatch = useAppDispatch();
  const [bridgeCompleted, setBridgeCompleted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [explorerData, setExplorerData] = useState<{ [key: string]: string }>(
    {}
  );
  const { signTransactions, addresses, agentsPreference, signTypedMessage } =
    useWallets();

  const fetchExplorerData = async () => {
    try {
      const fromExplorer = await serverApi.getChainExplorerUrl(data.from_chain);
      const toExplorer = await serverApi.getChainExplorerUrl(data.to_chain);

      setExplorerData({
        fromTokenExplorer: fromExplorer,
        toTokenExplorer: toExplorer,
      });
    } catch (e) {
      console.error("Failed to fetch explorer data", e);
    }
  };

  useEffect(() => {
    fetchExplorerData();
  }, [data]);

  // For Meson Bridge it's a signature, not a sign transaction
  const sendSignature = async (message: MesonMessage) => {
    try {
      const signature = (await signTypedMessage(
        WalletType.EVM,
        message.hash_to_sign
      )) as string;
      sendMessageHidden(
        `
        Use ${data.protocol_name} to continue bridge sending this params:
        Signature: ${signature}. - Encoded Swap: ${message.encodedSwap} - Wallet Address: ${addresses.EVM}`
      );
      return signature;
    } catch (e: any) {
      if (e.message === "User rejected the signature request") {
        setError("Signature request was rejected by the user");
      } else {
        setError("An unexpected error occurred during signature request");
      }
    }
  };

  function onBridge() {
    signTransactions(data.transactions).then(async (res) => {
      if (res.error) {
        if (res.error === "switching network") {
          onBridge();
          return;
        }
        setError("Error: " + res.error);
        return;
      } else if (res.hashes.length !== data.transactions.length) {
        setError("Error: not all transactions were signed");
        return;
      }

      // If there is a message to sign, sign it
      // Meson Bridge for example can have transactions (approve) and a message to sign
      let messageSignature = null;
      if (data.message_to_sign) {
        messageSignature = await sendSignature(data.message_to_sign[0]);
        if (!messageSignature) {
          return;
        }
      }
      // avoid component to be reused
      setBridgeCompleted(true);
      onDone();

      // submit the transaction to the server for analytics
      // if there was a message to sign, we don't submit the transaction yet
      // For Meson we still need to send that signature via API to complete the bridge
      if (!data.message_to_sign) {
        const walletType = getWalletTypeFromTransaction(data.transactions[0]);
        let walletAddress: string | undefined;

        if (walletType === "COSMOS") {
          walletAddress = data.from_address;
        } else if (data.from_chain.toLowerCase() === "solana") {
          walletAddress = addresses.SOLANA!;
        } else {
          walletAddress = addresses.EVM!;
        }

        submit({
          transaction: data,
          signature: res.hashes[res.hashes.length - 1],
          walletAddress: walletAddress,
          protocol_name: data.protocol_name,
          bridge_id: data.bridge_id ?? "",
        });

        dispatch(
          updateTokenConfetti(data.to_token.logoURI || SphereIconLogoGreen)
        );
        // update the balances
        serverApi
          .getBalances(
            addresses.EVM!,
            addresses.SOLANA!,
            addresses.SUI!,
            addresses.COSMOS!,
            addresses.BITCOIN!
          )
          .then((balances) => {
            dispatch(updateBalances(balances));
          });
      }
    });
  }

  return (
    <div className="bg-gray-800 border border-gray-700 shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4  max-w-[550px]">
      <div className="bg-gray-700 border border-gray-600 rounded-lg p-3 flex justify-between items-center gap-4 w-full h-70px">
        {/* Input Token */}
        <div className="flex flex-row items-start gap-2 w-1/3">
          <div className="flex flex-col justify-center gap-1">
            <span className="text-white font-medium text-sm leading-6">
              {formatDecimals(data.from_amount, 10)}
            </span>
            <div className="flex flex-row items-center gap-2">
              <img
                src={data.from_token.logoURI}
                alt={data.from_token.symbol}
                className="w-6 h-6 rounded-full"
              />
              <span className="text-white font-medium text-sm leading-6">
                {data.from_token.symbol}
              </span>
            </div>
            <span className="text-xs text-left">
              {data.from_chain.toUpperCase()}
            </span>
            <span className="text-xs text-left">
              ${Number(data.from_token_usd).toFixed(2) || 0}
            </span>
            <span className="text-xs text-left">
              {shortenAddress(data.from_address)}
            </span>
          </div>
        </div>

        <div>
          <SwapIcon />
        </div>

        {/* Output Token */}
        <div className="flex flex-row items-start justify-end gap-2 w-1/3">
          <div className="flex flex-col justify-center gap-1">
            <span className="text-white text-right font-medium text-sm leading-6">
              {formatDecimals(data.to_amount, 10)}
            </span>
            <div className="flex flex-row gap-2 justify-end">
              <img
                src={data.to_token.logoURI}
                alt={data.to_token.symbol}
                className="w-6 h-6 rounded-full"
              />
              <span className="text-white text-right font-medium text-sm leading-6">
                {data.to_token.symbol}
              </span>
            </div>
            <span className="text-xs text-right">
              {data.to_chain.toUpperCase()}
            </span>
            <span className="text-xs text-right">
              ${Number(data.to_token_usd).toFixed(2) || 0}
            </span>
            <span className="text-xs text-right">
              {shortenAddress(data.to_address)}
            </span>
          </div>
        </div>
      </div>

      {/* Other details */}
      {data.provider_fee && (
        <div className="flex flex-row items-center px-1 w-full">
          <InfoIcon />
          <span className="text-white font-semibold text-sm leading-6 ml-2">
            Providers Fee:
          </span>
          <span className="text-white text-sm leading-6 ml-auto">
            {Number(data.provider_fee).toFixed(4)} USD
          </span>
        </div>
      )}

      {data.gas_cost && (
        <div className="flex flex-row items-center px-1 w-full">
          <InfoIcon />
          <span className="text-white font-semibold text-sm leading-6 ml-2">
            Gas Cost (Network fee):
          </span>
          <span className="text-white text-sm leading-6 ml-auto">
            {data.gas_cost} USD
          </span>
        </div>
      )}

      <div className="flex flex-row items-center px-1 w-full">
        <ClockIcon />
        <span className="text-white font-semibold text-sm leading-6 ml-2">
          Estimated Time:
        </span>
        <span className="text-white text-sm leading-6 ml-auto">
          {data.estimated_time.toFixed(2)} min
        </span>
      </div>

      {data.slippage && (
        <div className="flex flex-row items-center px-1 w-full">
          <SlippageIcon />
          <span className="text-gray-400 text-sm leading-6 ml-2">
            Slippage Setting:
          </span>
          <span className="text-gray-400 text-sm leading-6 ml-auto">
            {data.slippage} %
          </span>
        </div>
      )}

      {data.order && (
        <div className="flex flex-row items-center px-1 w-full">
          <span
            className="text-gray-400 text-sm leading-6"
            data-tooltip-id="my-tooltip"
            data-tooltip-html={routePriorityTooltipContent}
          >
            <OrderIcon />
          </span>
          <Tooltip id="my-tooltip" place="top-start" />
          <span className="text-gray-400 text-sm leading-6 ml-2">
            Route Priority:
          </span>
          <span className="text-gray-400 text-sm leading-6 ml-auto">
            {data.order}
          </span>
        </div>
      )}

      {data.tool && (
        <div className="flex flex-row justify-start items-center px-1 w-full">
          <SwapRoute />
          <span className="text-gray-400 text-sm leading-6 ml-2">
            {data.tool} via
          </span>
          <span className="text-gray-400 text-sm leading-6 ml-2">
            {data.agent}
          </span>
        </div>
      )}
      {!NATIVE_TOKEN_ADDRESSES.includes(data.from_token.address) && (
        <div className="flex flex-row items-center px-1 w-full">
          <span className="text-gray-400  text-sm leading-6 ml-2">
            From Token Address:
          </span>
          <span className="text-gray-400 text-sm leading-6 ml-auto">
            <a
              href={`${explorerData.fromTokenExplorer}/token/${data.from_token.address}`}
              target="_blank"
              rel="noreferrer"
            >
              {shortenAddress(data.from_token.address)}
            </a>
          </span>
        </div>
      )}

      {!NATIVE_TOKEN_ADDRESSES.includes(data.to_token.address) && (
        <div className="flex flex-row items-center px-1 w-full">
          <span className="text-gray-400 text-sm leading-6 ml-2">
            To Token Address:
          </span>
          <span className="text-gray-400 text-sm leading-6 ml-auto">
            <a
              href={`${explorerData.toTokenExplorer}/token/${data.to_token.address}`}
              target="_blank"
              rel="noreferrer"
            >
              {shortenAddress(data.to_token.address)}
            </a>
          </span>
        </div>
      )}

      {agentsPreference ? (
        <div className="w-full">
          <p>
            Confirm the action with the agent to get all executed as a batch
          </p>
        </div>
      ) : (
        <div className="text-left w-full grid grid-cols-2 gap-2">
          <button
            onClick={onDone}
            disabled={bridgeCompleted}
            className="w-full text-white text-sm bg-gray-500 hover:bg-gray-500/80 rounded-lg px-4 py-2 disabled:bg-gray-500/70 disabled:text-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onBridge}
            disabled={bridgeCompleted}
            className="w-full text-white text-sm bg-blue-500 hover:bg-blue-500/80 rounded-lg px-4 py-2 disabled:bg-blue-500/70 disabled:text-gray-300"
          >
            Confirm
          </button>
        </div>
      )}
      <div className="flex flex-row items-center px-1 w-full">
        <span className="text-gray-400 font-semibold text-xs leading-6 ml-auto">
          AI Agent:
        </span>
        <span className="text-gray-400 text-xs leading-6 ml-2">
          {data.agent}
        </span>
      </div>

      {error && (
        <AlertDialog
          open={!!error}
          title={"Oops!"}
          description={error}
          actionLabel="Accept"
          onActionPress={() => setError("")}
        />
      )}
    </div>
  );
}

function shortenAddress(address: string) {
  return address.slice(0, 6) + "..." + address.slice(-6);
}

function formatDecimals(number: number, maxDecimals: number) {
  return Number.parseFloat(number.toFixed(maxDecimals)).toString();
}
