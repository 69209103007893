import React, { useState } from "react";
import { WalletType, useWallets } from "../../../../hooks/wallets/use-wallets";

export interface SignMessageProps {
  data: { message: string; chain: string };
  sendMessageHidden: (signature: string) => Promise<void>;
}

export const SignMessageView = ({
  data,
  sendMessageHidden,
}: SignMessageProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string | undefined>(undefined);
  const { signMessage } = useWallets();
  const sendSignature = async (message: string) => {
    setLoading(true);
    const signature = (await signMessage(WalletType.EVM, message)) as string;
    setSignature(signature);
    setLoading(false);
    return sendMessageHidden(`Signature: ${signature}.`);
  };

  const termsUrl =
    data.chain === "KAVA"
      ? "https://kinetix.finance/terms"
      : "https://quickswap.exchange/#/tos";

  return (
    <div
      className={`bg-gray-800 border border-gray-600 shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4 max-w-[550px] sm:min-w-[450px]`}
    >
      <div className="flex flex-col items-center gap-4">
        <h3 className="text-lg font-semibold">Sign Message</h3>
      </div>

      <div className="bg-gray-700 border border-gray-600 rounded-lg p-3 flex justify-between items-center gap-4 w-full h-70px">
        <p>{data.message}</p>
      </div>
      <div className="flex justify-center items-center w-full font-bold">
        <a href={termsUrl} target="_blank">
          {data.chain === "KAVA" ? "Kinetix" : "Quickswap"} Terms & Conditions
        </a>
      </div>
      <button
        className="bg-gradient-to-r from-[#274690] to-[#576CA8] text-white font-semibold rounded-lg p-2 w-full"
        disabled={loading || !!signature}
        onClick={() => sendSignature(data.message)}
      >
        {!signature ? (!loading ? "Sign Message" : "Signing...") : "Signed!"}
      </button>
    </div>
  );
};
