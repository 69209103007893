import { useEffect, useState } from "react";
import { images } from "../assets/images";
import { serverEndpoint } from "../utils/server";
import axios from "axios";
import { Link } from "react-router-dom";

export function LeaderboardScreen() {
  const [analytics, setAnalytics] = useState<{
    hottest_token: {
      image: string;
      name: string;
    };
    messages: number;
    ranking: {
      address: string;
      messages: number;
      transactions: number;
      volume: number;
    }[];
    releases: string[];
    transactions: number;
    volume: number;
    wallets: number;
  } | null>(null);
  const [selectedRelease, setSelectedRelease] = useState<string>("GLOBAL");

  async function fetchStats(release: string = "global") {
    setAnalytics(null);
    const res = await axios.get(
      `${serverEndpoint}/leaderboard?release=${release}`
    );
    setAnalytics(res.data);
  }

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className="px-4">
      <div className=" max-w-5xl mx-auto py-14 text-center">
        <img
          src={images.SphereLogo}
          alt="Sphere logo"
          className="h-14 w-14 rounded-full mx-auto mb-8"
        />
        <h1 className="text-2xl text-gray-300">Surface Area Leaderboard</h1>
        <p className="text-gray-400 mt-2">
          A place where we rank users by volume
        </p>

        <Link to={"/"}>
          <button className="px-4 py-2 mt-4 bg-blue-500 hover:bg-blue-600 rounded-lg text-xs text-white">
            Go to chat
          </button>
        </Link>
      </div>

      {/* Select release */}
      <div className="max-w-5xl mx-auto">
        <select
          value={selectedRelease}
          onChange={(e) => {
            setSelectedRelease(e.target.value);
            fetchStats(e.target.value);
          }}
          className="bg-gray-700 py-2 px-2 rounded-lg text-white w-[200px] relative">
          <option value="global">Global</option>
          {analytics
            ? analytics.releases
                .filter((r) => r !== "global")
                .map((r, index) => {
                  let label;
                  if (r.toUpperCase() === "ACTIVATION-1")
                    label = "$PONKE Volume Challenge";
                  else if (r.toUpperCase() === "ACTIVATION-2")
                    label = "$MUMU Volume Challenge";
                  return (
                    <option key={index} value={r}>
                      {label ?? r.toUpperCase()}
                    </option>
                  );
                })
            : null}
        </select>
      </div>

      {/* Global statistics */}
      <div className="max-w-5xl mx-auto mb-2">
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mt-4 *:bg-gray-700 *:rounded-lg *:text-white *:w-full *:p-4">
          {/* volume */}
          <div className="">
            <p className="text-sm text-gray-400">Volume</p>
            <TextWithFallback
              text={"$" + analytics?.volume.toFixed(2)}
              loading={!analytics}
            />
          </div>

          {/* wallets */}
          <div className="">
            <p className="text-sm text-gray-400">Wallets connected</p>
            <TextWithFallback
              text={String(analytics?.wallets)}
              loading={!analytics}
            />
          </div>

          {/* messages */}
          <div className="">
            <p className="text-sm text-gray-400">Messages</p>
            <TextWithFallback
              text={String(analytics?.messages)}
              loading={!analytics}
            />
          </div>

          {/* transactions */}
          <div className="">
            <p className="text-sm text-gray-400">Transactions</p>
            <TextWithFallback
              text={String(analytics?.transactions)}
              loading={!analytics}
            />
          </div>
        </div>
      </div>

      {/* Hotest token */}
      <div className="max-w-5xl mx-auto mb-2">
        <div className="bg-gray-700 rounded-lg text-white w-full p-4">
          <p className="text-sm text-gray-400">Hottest token</p>
          {!analytics ? (
            <div className="bg-gray-600 h-6 rounded w-full mt-2 animate-pulse"></div>
          ) : (
            <div className="flex justify-between">
              <div className="flex items-center space-x-2">
                <img
                  className="w-5 h-5 rounded-full"
                  src={analytics?.hottest_token.image}
                  alt=""
                />
                <p>{analytics?.hottest_token.name}</p>
              </div>

              <Link to={"/"}>
                <button className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded-lg text-xs text-white">
                  Go trade!
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Leaderboard */}
      <div className="relative overflow-x-auto max-w-5xl mx-auto rounded-lg mb-20">
        <table className="w-full text-sm text-left rtl:text-right text-gray-400">
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th className="px-6 py-3">#</th>
              <th className="px-6 py-3 w-full">Wallet</th>
              <th className="px-6 py-3">Volume</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {!analytics && (
              <tr className=" bg-gray-800">
                <td colSpan={3} className="text-center py-4">
                  Loading...
                </td>
              </tr>
            )}

            {analytics?.ranking
              .filter((r) => r.volume > 0)
              .map((r, i) => (
                <tr key={i} className=" bg-gray-800">
                  <th className="px-6 py-4 text-white">
                    {i === 0 ? "🥇" : i === 1 ? "🥈" : i === 2 ? "🥉" : i + 1}
                  </th>
                  <td className="px-6 py-4 w-full">{r.address}</td>
                  <td className="px-6 py-4">${r.volume.toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TextWithFallback(props: { text?: string; loading: boolean }) {
  return props.loading ? (
    <div className="bg-gray-600 h-6 rounded w-full mt-2 animate-pulse"></div>
  ) : (
    <p className="font-bold text-xl">{props.text}</p>
  );
}
