import { ReactNode } from "react";
import {
  ExclamationIcon,
  LoadingIcon,
  SuccessIcon,
} from "../../assets/icons/flowbite-icons";

export const StatusCard = ({
  title,
  message,
  status,
}: {
  title: string;
  message: ReactNode;
  status: "success" | "waiting" | "sent" | "error";
}) => {
  const icon =
    status === "success" || status === "sent" ? (
      <SuccessIcon />
    ) : status === "error" ? (
      <ExclamationIcon fill="fill-red-500" />
    ) : (
      <LoadingIcon fill="fill-white" size="w-4 h-4" />
    );

  return (
    <div
      className={`flex flex-col justify-center items-start p-4 gap-3 bg-gray-800 border rounded-md min-w-full sm:min-w-[50vh] ${
        status === "waiting"
          ? "animate-pulse border-slate-600"
          : status === "error"
          ? "border-red-900"
          : "border-green-900"
      }`}
      role="status"
    >
      <div className="flex items-center gap-2">
        {icon}
        <h2 className="text-white font-semibold">{title}</h2>
      </div>
      {message}
    </div>
  );
};
