import { StatusCard } from "../../themed/status-card";

enum TransactionStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SENT = "SENT", // BITCOIN TRANSACTION TAKES SOME TIME TO APPEAR ON THE SCAN
}

const protocolsExplorers: Record<
  string,
  { ExplorerUrl: string; Text: string }
> = {
  lifi: {
    ExplorerUrl: "https://explorer.li.fi/tx/",
    Text: "Check Bridge/Swap Status on LiFi Explorer",
  },
  squid: {
    ExplorerUrl: "https://axelarscan.io/gmp/",
    Text: "Check Bridge on Axelar Explorer",
  },
  decent: {
    ExplorerUrl: "https://www.decentscan.xyz/",
    Text: "Check Bridge/Swap Status on Decent Explorer",
  },
  synapse: {
    ExplorerUrl: "https://explorer.synapseprotocol.com/txs?hash=",
    Text: "Check Bridge Status on Synapse Explorer",
  },
  meson: {
    ExplorerUrl: "https://explorer.meson.fi/swap/",
    Text: "Check Bridge Status on Meson Explorer",
  },
  rango: {
    ExplorerUrl: "https://scan.rango.exchange/swap/",
    Text: "Check Status on Rango Explorer",
  },
  thorchain: {
    ExplorerUrl: "https://thorchain-explorer-v2.vercel.app/tx/",
    Text: "Check Status on ThorChain Explorer",
  },
};

function buildExplorerUrl(
  protocol: string,
  hash: string,
  chainId?: string | number,
  bridgeId?: string
) {
  if (protocol.toLowerCase() === "decent") {
    return `${
      protocolsExplorers[protocol.toLowerCase()].ExplorerUrl
    }?chainId=${chainId}&txHash=${hash}`;
  }
  if (protocol.toLowerCase() === "rango") {
    // Rango Explorer only works with bridgeId
    return `${
      protocolsExplorers[protocol.toLowerCase()].ExplorerUrl
    }${bridgeId}`;
  }
  return `${protocolsExplorers[protocol.toLowerCase()].ExplorerUrl}${hash}`;
}

export const TxStatus = ({
  data,
}: {
  data: {
    status: TransactionStatus;
    tx_url?: string;
    tx_hash: string;
    chain_id: string | number;
    protocol_name?: string;
    bridge_id?: string;
  };
}) => {
  const protocol = data.protocol_name?.toLowerCase();
  const explorer = protocol && protocolsExplorers[protocol];

  return (
    <div className="mt-4">
      <StatusCard
        title={`Transaction ${
          data.status === TransactionStatus.SUCCESS
            ? "Confirmed!"
            : data.status === TransactionStatus.PENDING
            ? "Pending..."
            : data.status === TransactionStatus.SENT
            ? "Sent!"
            : "Failed!"
        }`}
        message={
          <div className="flex flex-col gap-4 text-gray-400">
            {data.tx_url && (
              <a
                href={data.tx_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                View Transaction on Blockchain Explorer
                {data.status === TransactionStatus.SENT && (
                  <p>
                    Transaction may take some time to appear on the explorer and
                    be confirmed.
                  </p>
                )}
              </a>
            )}
            {explorer && (
              <a
                href={buildExplorerUrl(
                  data.protocol_name!,
                  data.tx_hash,
                  data?.chain_id,
                  data?.bridge_id
                )}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                {protocolsExplorers[data.protocol_name!.toLowerCase()].Text}
              </a>
            )}
          </div>
        }
        status={
          data.status === TransactionStatus.SUCCESS
            ? "success"
            : data.status === TransactionStatus.PENDING
            ? "waiting"
            : data.status === TransactionStatus.SENT
            ? "sent"
            : "error"
        }
      />
    </div>
  );
};
