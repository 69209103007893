import { useChat } from "../../hooks/use-chat";
import { usePreferences } from "../../hooks/use-preferences";
import { GptModel } from "../../services/server";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export function GptModelSelect() {
  const { messages, gptModels } = useChat();
  const { preferences, updatePreferences } = usePreferences();
  return (
    <Select
      disabled={messages.length > 0}
      value={preferences.gptModel?.model}
      onValueChange={(value) =>
        updatePreferences({
          gptModel: gptModels.find((m: GptModel) => m.model === value),
        })
      }
    >
      <SelectTrigger>
        <SelectValue placeholder="Select model" />
      </SelectTrigger>
      <SelectContent>
        {gptModels.map((m: GptModel) => (
          <SelectItem key={m.model} value={m.model}>
            {m.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
