import { createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "./userThunk";
import { Balance } from "../../components/chat/balances";

export interface UserState {
  isVerified?: boolean;
  isAuthenticated: boolean;
  loading?: boolean;
  error?: string;
  uid?: string | null;
  name?: string;
  email?: string | null;
  username?: string;
  profilePicture?: string | undefined;
  countryCode?: string | any;
  countryFlag?: string;
  apiKey?: string;
  isSidebarOpen: boolean;
  testApiKey?: string;
  accessToken?: string;
  idToken?: string;
  merchantUid?: string;
  walletAddress?: string;
  balances?: Balance[];
  destinationToken?: string;
}

const initialState: UserState = {
  accessToken: undefined,
  isAuthenticated: false,
  loading: true,
  isSidebarOpen: window.innerWidth >= 860,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signOut: (state) => {
      state = initialState;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    updateBalances: (state, action) => {
      state.balances = action.payload;
    },
    updateTokenConfetti: (state, action) => {
      state.destinationToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // user info
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
        isAuthenticated: true,
      };
    });
  },
});

export const {
  signOut,
  updateAccessToken,
  setWalletAddress,
  updateBalances,
  updateTokenConfetti,
} = userSlice.actions;
export default userSlice.reducer;
