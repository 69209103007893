import { useState } from "react";
import { SwapIcon } from "../../../../assets/icons/flowbite-icons";
import { Message } from "../../../../hooks/use-chat";
import { Submit } from "../../../../hooks/use-chat";
import {
  getWalletTypeFromTransaction,
  useWallets,
} from "../../../../hooks/wallets/use-wallets";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateBalances } from "../../../../redux/slices/user";
import { serverApi } from "../../../../services/server";
import { AlertDialog } from "../../../themed/alert-dialog";

enum Transaction_Order_Type {
  APPROVE_PLUGIN = "approve_plugin",
  APPROVE_ALLOWANCE = "approve_allowance",
  SUBMIT_TXN = "submit_txn",
  SIGN_MESSAGE = "sign_message", // there's a flow in Perps v2 that requires user to sign a message and pass it back to the server
}

interface Transaction_Data {
  order: number;
  step: string;
  data: any;
}

export interface DisplayDataProps {
  amountIn: string | undefined;
  amountInSymbol: string;
  amountInPrice: string | undefined;
  amountInUSD: string;
  amountOut: string | undefined;
  amountOutSymbol: string;
  amountOutPrice: string | undefined;
  amountOutUSD: string;
  assetTokenSymbol: string | undefined;
  assetTokenPrice: string | undefined;
  remainingAmount: string | undefined;
  percent: string | undefined;
  leverage: string | undefined;
  isLong: boolean | undefined;
  limitPrice: string | undefined;
  stopLossPrice: string | undefined;
  takeProfitPrice: string | undefined;
  orderIndex: string | undefined;
}

interface SignTransactionStruct {
  type: string;
  title: string;
  transactionId: string;
  buttonMessage: string;
  displayData: DisplayDataProps | null;
  transactions: Transaction_Data[] | null;
}

//
export const PerpsSignTransactionView = ({
  data,
  setMessages,
  submit,
  active,
  onDone,
}: {
  data: SignTransactionStruct;
  submit: (data: Submit) => void;
  active: boolean;
  onDone: () => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}) => {
  const dispatch = useAppDispatch();
  const [transactionCompleted, setTransactionCompleted] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { signTransactions, addresses, agentsPreference } = useWallets();

  function onSubmit() {
    if (!data.transactions) return;

    const transactions = data.transactions
      .filter(
        (transaction) =>
          transaction.step !== Transaction_Order_Type.SIGN_MESSAGE
      )
      .map((transaction) => transaction.data);

    const wallet = getWalletTypeFromTransaction(transactions[0]);
    signTransactions(transactions, wallet).then((res) => {
      if (res.error) {
        if (res.error === "switching network") {
          onSubmit();
          return;
        }
        setError("Error: " + res.error);
        return;
      } else if (res.hashes.length !== transactions.length) {
        setError("Error: not all transactions were signed");
        return;
      }

      // avoid component to be reused
      setTransactionCompleted(true);
      onDone();

      // submit the transaction to the server for analytics
      submit({
        transaction: data,
        signature: res.hashes[res.hashes.length - 1],
        walletAddress: addresses.EVM!,
      });

      // update the balances
      serverApi
        .getBalances(
          addresses.EVM!,
          addresses.SOLANA!,
          addresses.SUI!,
          addresses.COSMOS!,
          addresses.BITCOIN!
        )
        .then((balances) => {
          dispatch(updateBalances(balances));
        });
    });
  }

  return (
    <>
      <div
        className={`bg-gray-800 border  shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4 min-w-full sm:min-w-[450px] ${
          transactionCompleted ? "border-green-900" : "border-gray-700"
        }`}
      >
        {/* Title */}
        <div className="flex flex-col items-center gap-4">
          <h3 className="text-lg font-semibold">{data.title}</h3>
        </div>

        {/* Display Data */}
        <div className="bg-gray-700 border border-gray-600 rounded-lg p-3 flex justify-between items-center gap-4 w-full h-70px">
          {/* Input Token */}
          <div className="flex flex-row items-start gap-2">
            <div className="flex flex-col justify-center gap-1">
              <span className="text-white font-medium text-sm leading-6">
                {data?.displayData?.amountIn
                  ? Number(data?.displayData?.amountIn).toFixed(4)
                  : ""}{" "}
                {data?.displayData?.amountInSymbol}
              </span>
              <span className="text-gray-4000 text-sm leading-6">
                ${Number(data?.displayData?.amountInUSD).toFixed(2)}
              </span>
            </div>
          </div>

          {data.displayData?.amountOut && ( // PerpsV2 Liquidity doesn't have output token
            <>
              <div>
                <SwapIcon />
              </div>
              {/* Output Token */}
              <div className="flex flex-row items-start gap-2">
                <div className="flex flex-col justify-center gap-1">
                  <span className="text-white font-medium text-sm leading-6">
                    {data?.displayData?.amountOut
                      ? Number(data?.displayData?.amountOut).toFixed(4)
                      : ""}{" "}
                    {data?.displayData?.amountOutSymbol}
                  </span>
                  <span className="text-gray-400 text-sm leading-6">
                    ${Number(data?.displayData?.amountOutUSD).toFixed(2)}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Details */}
        {data?.displayData?.amountInPrice && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              {data?.displayData?.amountInSymbol} Price
            </span>
            <span className="text-white text-sm leading-6 ml-auto">
              ${Number(data?.displayData?.amountInPrice).toFixed(2)}
            </span>
          </div>
        )}
        {data?.displayData?.amountOutPrice && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              {data?.displayData?.amountOutSymbol} Price
            </span>
            <span className="text-white text-sm leading-6 ml-auto">
              ${Number(data?.displayData?.amountOutPrice).toFixed(2)}
            </span>
          </div>
        )}
        {data?.displayData?.assetTokenSymbol && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Asset: {data?.displayData?.assetTokenSymbol}
            </span>
          </div>
        )}
        {data?.displayData?.leverage && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Leverage: {data?.displayData?.leverage}x
            </span>
          </div>
        )}
        {data?.displayData?.remainingAmount && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Remaining: $
              {Number(data?.displayData?.remainingAmount).toFixed(2)}
            </span>
          </div>
        )}
        {data?.displayData?.percent && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Percent: {data?.displayData?.percent}%
            </span>
          </div>
        )}
        {data?.displayData?.orderIndex && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Order Id: {data?.displayData?.orderIndex}
            </span>
          </div>
        )}
        {data?.displayData?.limitPrice && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Limit Price: ${Number(data?.displayData?.limitPrice).toFixed(2)}
            </span>
          </div>
        )}
        {data?.displayData?.stopLossPrice && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Stop Loss Price: $
              {Number(data?.displayData?.stopLossPrice).toFixed(2)}
            </span>
          </div>
        )}
        {data?.displayData?.takeProfitPrice && (
          <div className="flex flex-row items-center px-1 w-full">
            <span className="text-white font-semibold text-sm leading-6">
              Take Profit Price: $
              {Number(data?.displayData?.takeProfitPrice).toFixed(2)}
            </span>
          </div>
        )}

        {/* Submit Button */}
        {!transactionCompleted && !agentsPreference && (
          <button
            className="bg-gradient-to-r from-[#274690] to-[#576CA8] text-white font-semibold rounded-lg p-2 w-full"
            onClick={onSubmit}
            disabled={transactionCompleted}
          >
            {data.buttonMessage}
          </button>
        )}

        {agentsPreference && (
          <div className="w-full">
            <p>
              Confirm the action with the agent to get all executed as a batch
            </p>
          </div>
        )}

        {error && (
          <AlertDialog
            open={!!error}
            title={"Oops!"}
            description={error}
            actionLabel="Accept"
            onActionPress={() => setError("")}
          />
        )}
      </div>
    </>
  );
};
