import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProfileInfo } from "../../utils/server";

export const getUserInfo = createAsyncThunk(
  "get/userinfo",
  async (
    {
      uid,
      accessToken,
      idToken,
    }: { uid: string; accessToken: string; idToken: string },
    { rejectWithValue }
  ) => {
    try {
      const { userData, merchantData } = await getProfileInfo(
        accessToken,
        idToken
      );

      const data = {
        isVerified: merchantData?.isVerified,
        isAuthenticated: true,
        isSidebarOpen: window.innerWidth >= 860,
        uid: uid,
        name: userData?.name,
        email: userData.email,
        username: userData?.username,
        profilePicture: userData?.profilePicture,
        countryCode: userData?.countryCode,
        countryFlag: userData?.countryFlag,
        apiKey: merchantData?.apiKey,
        testApiKey: merchantData?.testApiKey,
        theme: merchantData?.theme,
        accessToken,
        idToken,
        merchantUid: merchantData?.uid,
      };

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
