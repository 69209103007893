import React from "react";
import { useState } from "react";

import { images } from "../../assets/images";
import { useAppSelector } from "../../redux/hooks";

export interface Balance {
  address: string;
  amount: number;
  price?: number;
  name: string;
  chain: string;
  symbol: string;
  decimals: number;
  logo_uri: string;
  usd_amount?: number;
}

export const BalancesDropdown = () => {
  const balances = useAppSelector((state) => state.user.balances);
  const [showBalances, setShowBalances] = React.useState(false);
  return (
    <>
      {balances && balances.length > 0 && (
        <div className="flex flex-col w-full bg-gray-800 rounded-lg px-2 py-4 gap-x-1">
          <div
            onClick={() => {
              if (balances) {
                setShowBalances(!showBalances);
              }
            }}
            className="flex flex-row hover:cursor-pointer justify-between"
          ></div>
          <div
            className={`flex pt-2 transition-all duration-1000 ease-in-out overflow-hidden max-h-[500px] sm:max-h-[650px] opacity-100`}
            style={{ transitionProperty: "max-height, opacity" }}
          >
            {balances && <TokenBalanceList balances={balances} />}
          </div>
        </div>
      )}
    </>
  );
};

const TokenBalanceList: React.FC<{ balances: Balance[] }> = ({ balances }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="flex flex-col gap-y-3 mb-2 px-1 overflow-y-auto">
      {balances
        ?.filter((balance) => balance.amount > 0)
        .map((balance) => (
          <button
            key={balance.address + balance.chain}
            className="border border-gray-600 rounded-lg py-px my-1 flex flex-row items-center justify-between px-5 hover:bg-gray-700 cursor-pointer active:bg-gray-800 transform transition-all"
            onClick={() =>
              copyToClipboard(`${balance.symbol} ${balance.address}`)
            }
          >
            <div className="flex flex-row items-center gap-x-3">
              <img
                className="w-10"
                alt={`${balance.symbol} icon`}
                src={balance.logo_uri}
                onError={(e) => {
                  e.currentTarget.src = images.DefaultCryptoLogo;
                }}
              />
              <div>
                <h1 className="font-semibold text-white">{balance.symbol}</h1>
                <span className="text-gray-400 font-light text-sm">
                  {balance.chain}
                </span>
              </div>
            </div>

            <div className="flex flex-col items-end justify-end w-1/2 px-6">
              <span className="text-white">
                {balance.amount > 1
                  ? String(balance.amount.toFixed(2))
                  : String(balance.amount)}
              </span>
              {balance?.usd_amount ? (
                <span className="text-gray-400 font-light text-sm">
                  ${balance.usd_amount.toFixed(2)}
                </span>
              ) : null}
            </div>
          </button>
        ))}
    </div>
  );
};
