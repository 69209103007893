import { createContext, useContext, useState } from "react";
import axios from "axios";

import {
  SigningStargateClient,
  GasPrice,
  calculateFee,
} from "@cosmjs/stargate";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
import { toUtf8 } from "@cosmjs/encoding";
import { WalletProvider, WalletSignerContextProvider } from "./wallet-provider";
import { AssembledTransaction, CosmosTransactionData } from "../use-wallets";
import { OfflineSigner } from "@cosmjs/proto-signing";

interface Wallet {
  address: string;
  chains: string[];
  type: string;
  privateKey: string;
}

interface Chain {
  chain_name: string;
  chain_id: string;
  bech32_prefix: string;
  status: string;
}

interface ChainResponse {
  chains: Chain[];
}

type GasData = {
  gasLimit: string;
  gasPrice?: string;
  maxPriorityFeePerGas?: string;
  maxFeePerGas?: string;
};

interface EncodeObject {
  readonly typeUrl: string;
  readonly value: any;
}

type ParsedData = {
  msg: WasmHookMsg | IBC;
  msgTypeUrl: CosmosMsgType;
};

type IBC = {
  memo?: string;
  receiver?: string;
  sender?: string;
  sourcePort?: string;
  sourceChannel?: string;
  timeoutHeight?: any;
  timeoutTimestamp?: {
    low: number;
    high: number;
    unsigned: boolean;
  };
  token: {
    amount: string;
    denom: string;
  };
};

type WasmHookMsg = {
  wasm: {
    contract: string;
    msg: object;
  };
};

enum CosmosMsgType {
  IBC_TRANSFER = "/ibc.applications.transfer.v1.MsgTransfer",
  WASM = "/cosmwasm.wasm.v1.MsgExecuteContract",
}

const CosmosWalletContext = createContext<
  WalletSignerContextProvider | undefined
>(undefined);

export const useCosmosWallets = () => {
  const context = useContext(CosmosWalletContext);
  if (!context) {
    throw new Error(
      "useCosmosWallets must be used within a CosmosWalletContext"
    );
  }
  return context;
};

const getChainIdByWalletAddress = async (
  walletAddress: string
): Promise<string | null> => {
  try {
    // Fetch the list of chains
    const response = await axios.get<ChainResponse>(
      "https://as-proxy.servers.atomscan.com/chains.json"
    );
    const chains = response.data.chains;

    // Extract the Bech32 prefix from the wallet address
    const prefix = walletAddress.split("1")[0];

    // Find the chain with the matching Bech32 prefix
    const chain = chains.find(
      (chain) => chain.bech32_prefix === prefix && chain.status === "live"
    );

    if (chain) {
      return chain.chain_id;
    } else {
      console.error(`No chain found for the prefix: ${prefix}`);
      return null;
    }
  } catch (error) {
    console.error("Error fetching chain data:", error);
    return null;
  }
};

export const CosmosWalletProvider = ({ children }: { children: any }) => {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [address, setAddress] = useState<string | null>(null);

  async function connect(provider: WalletProvider | any): Promise<void> {
    try {
      const { keplr } = window as any;
      if (!keplr) {
        alert("Please install keplr extension");
        throw new Error("Please install keplr extension.");
      }

      await keplr.enable("cosmoshub-4");

      const offlineSigner = keplr.getOfflineSigner("cosmoshub-4");
      const accounts = await offlineSigner.getAccounts();

      setAddress(accounts[0].address);
    } catch (error: any) {
      if (error.message.includes("KeyRing is locked")) {
        throw new Error(
          "Keplr wallet is locked. Please unlock it and try again."
        );
      }
      throw new Error("Error connecting to wallet: " + error);
    }
  }

  async function disconnect(): Promise<void> {
    try {
      const { keplr } = window as any;
      if (!keplr) {
        throw new Error("Keplr not found");
      }
      // Keplr doesn't have a built-in disconnect method
      // We can simply clear the local state
      setAddress(null);
      setWallets([]);
    } catch (error) {
      throw new Error("Error disconnecting from wallet: " + error);
    }
  }

  async function signAndSendTransaction(
    tx: AssembledTransaction
  ): Promise<string> {
    try {
      const { keplr } = window as any;
      if (!keplr) {
        throw new Error("Wallet not connected");
      }

      const {
        from,
        gasLimit,
        data,
        chainId,
        rpc,
        amount,
        tokenDenom,
        maxFeePerGas,
        gasPrice,
      } = tx as CosmosTransactionData;

      await keplr.enable(chainId);
      const offlineSigner: OfflineSigner = await keplr.getOfflineSigner(
        chainId
      );
      const signer = await SigningStargateClient.connectWithSigner(
        rpc,
        offlineSigner
      );

      const signerAddress = from;
      const parsedData: ParsedData = JSON.parse(data);

      const handleTransactionError = (result: any) => {
        if (result.code !== undefined && result.code !== 0) {
          throw new Error(
            `Transaction failed with code ${result.code}: ${result.rawLog}`
          );
        }
        return result.transactionHash;
      };

      const signAndBroadcast = async (
        msgs: EncodeObject[],
        fee: any,
        memo: string = ""
      ) => {
        try {
          const result = await signer.signAndBroadcast(
            signerAddress,
            msgs,
            fee,
            memo
          );
          return handleTransactionError(result);
        } catch (error) {
          console.error("Transaction failed:", error);
          throw error;
        }
      };

      if (parsedData.msgTypeUrl === CosmosMsgType.WASM) {
        signer.registry.register(CosmosMsgType.WASM, MsgExecuteContract);
        const msg: WasmHookMsg = parsedData.msg as WasmHookMsg;

        const msgs: EncodeObject[] = [
          {
            typeUrl: parsedData.msgTypeUrl,
            value: {
              sender: signerAddress,
              contract: msg.wasm.contract,
              msg: toUtf8(JSON.stringify(msg.wasm.msg)),
              funds: [{ amount, denom: tokenDenom }],
            },
          },
        ];

        const estimatedGas = await signer.simulate(signerAddress, msgs, "");
        const gasMultiplier = Number(maxFeePerGas) || 1.3;
        const fee = calculateFee(
          Math.trunc(estimatedGas * gasMultiplier),
          GasPrice.fromString(gasPrice)
        );

        return await signAndBroadcast(msgs, fee);
      }

      if (parsedData.msgTypeUrl === CosmosMsgType.IBC_TRANSFER) {
        signer.registry.register(CosmosMsgType.IBC_TRANSFER, MsgTransfer);
        const msg: IBC = parsedData.msg as IBC;

        const timeoutTimestamp =
          BigInt(msg?.timeoutTimestamp?.low || 0) +
          (BigInt(msg?.timeoutTimestamp?.high || 0) << BigInt(32));

        const message = MsgTransfer.fromPartial({
          sourcePort: msg.sourcePort,
          sourceChannel: msg.sourceChannel,
          token: msg.token,
          sender: msg.sender,
          receiver: msg.receiver,
          timeoutTimestamp: timeoutTimestamp,
          memo: msg.memo,
        });

        const msgs: EncodeObject[] = [
          {
            typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
            value: message,
          },
        ];

        const simulate = await signer.simulate(signerAddress, msgs, "");

        const gasPriceNumber = parseFloat(gasPrice);
        const gasLimitNumber = Math.ceil(simulate * 1.3);
        const feeAmount = Math.ceil(gasPriceNumber * gasLimitNumber);

        const fee = {
          amount: [{ denom: tokenDenom, amount: feeAmount.toString() }],
          gas: gasLimitNumber.toString(),
        };

        return await signAndBroadcast(msgs, fee);
      }

      throw new Error("Unsupported message type");
    } catch (error: any) {
      if (
        error.message &&
        error.message.includes("transaction indexing is disabled")
      ) {
        console.warn(
          "Transaction indexing is disabled on the RPC node. Assuming transaction was successful."
        );
        return "TRANSACTION_SENT_BUT_NOT_CONFIRMED";
      }
      throw new Error("Error signing and sending transaction: " + error);
    }
  }

  async function signMessage(message: string): Promise<string> {
    try {
      const { keplr } = window as any;
      if (!keplr || !address) {
        throw new Error("Wallet not connected");
      }

      const chainId = await getChainIdByWalletAddress(address);
      const signature = await keplr.signArbitrary(chainId, address, message);
      return signature.signature;
    } catch (error) {
      throw new Error("Error signing message: " + error);
    }
  }

  return (
    <CosmosWalletContext.Provider
      value={{
        authSig: null,
        address,
        connect,
        disconnect,
        signAndSendTransaction,
        signMessage,
        agentsPreference: false,
        setAgentsPreference: () => {},
      }}>
      {children}
    </CosmosWalletContext.Provider>
  );
};
