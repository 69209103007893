import { useChat } from "../hooks/use-chat";
import { images } from "../assets/images/index";

export enum AgentSupportedActions {
  SWAP = "SWAP",
  BRIDGE = "BRIDGE",
  DEX = "DEX",
  PERPS = "PERPS",
  BALANCE = "BALANCE",
  ONRAMP = "ONRAMP",
  TRANSFER = "TRANSFER",
  NFT = "NFT",
  STAKING = "STAKING",
}
export interface AgentProps {
  name: string;
  examples: string[];
  description?: string;
  active: boolean;
  supportedChains: string[];
  action: AgentSupportedActions;
  img?: string;
  parent: string;
}

export default function AgentFunctionality() {
  const { availableAgents: agents } = useChat();
  return (
    <>
      <div className="flex flex-col w-full max-w-5xl rounded-lg px-2 py-4 gap-x-1">
        <div className="flex flex-row justify-between">
          <span className="rounded-lg text-white w-full relative">Agents</span>
        </div>
        <>
          {agents &&
            agents.map((agent: AgentProps) => (
              <div className="hover:bg-gray-400 flex p-2 rounded">
                <img
                  src={agent.img ?? images.DefaultAgentLogo}
                  className="w-10 h-10"
                />
                <div className="flex ">
                  <span className="text-gray-300 content-center p-2">
                    {agent.name}
                  </span>
                </div>
              </div>
            ))}
        </>
      </div>
    </>
  );
}
