export default function TransactionHash(props: {
  data: { hash: string; chain: string; link: string };
}) {
  return (
    <div className="bg-gray-800 p-4 mt-4 rounded-lg border border-gray-700">
      <div className="text-sm text-white mb-2">Transaction hash</div>
      
      <a href={props.data.link ?? "#"} className="text-sm text-blue-500 underline">
        {props.data.hash} 
      </a>
      <p className="text-gray-300 text-xs">{props.data.chain}</p>
    </div>
  );
}
