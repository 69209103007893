import React from "react";
import {
  AlertDialog as AlertDialogPrimitive,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";

interface AlertDialogProps {
  open: boolean;
  title: string;
  description: any;
  actionLabel: string;
  onActionPress: () => void;
  cancelLabel?: string;
  onCancelPress?: () => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  title,
  description,
  actionLabel,
  onActionPress,
  cancelLabel,
  onCancelPress,
}) => {
  return (
    <AlertDialogPrimitive open={open}>
      <AlertDialogContent className="w-full max-w-md flex flex-col max-h-[80vh]">
        <AlertDialogHeader className="flex-shrink-0">
          <AlertDialogTitle>{title}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription className="break-words whitespace-normal overflow-y-auto max-h-[50vh] mb-4">
          {description}
        </AlertDialogDescription>
        <AlertDialogFooter className="flex-shrink-0">
          <AlertDialogAction onClick={onActionPress}>
            {actionLabel}
          </AlertDialogAction>
          {cancelLabel && onCancelPress && (
            <AlertDialogCancel onClick={onCancelPress}>
              {cancelLabel}
            </AlertDialogCancel>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogPrimitive>
  );
};
