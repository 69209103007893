import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface ModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  customStyles?: string;
}

const Modal = ({ children, visible, setVisible, customStyles }: ModalProps) => {
  return visible ? (
    <div className="absolute bg-black/90 z-40 h-screen w-full flex justify-center top-0 items-center left-0 p-2">
      <div
        className={`bg-gray-900 p-4 w-96 rounded-xl flex justify-center items-center flex-col relative ${customStyles}`}
      >
        <button
          onClick={() => setVisible(false)}
          className="absolute top-2.5 right-3.5"
        >
          <XMarkIcon width={25} color="white" />
        </button>

        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;
