import { useState } from "react";

interface OrderProps {
  orderIndex: string;
  positionSize: string;
  assetTokenSymbol: string;
  isLong: boolean;
  limitPrice: string;
  orderType?: string | undefined;
  purchaseTokenSymbol?: string | undefined;
  orderId: string | undefined;
}

interface SelectOptionStruct {
  title: string;
  subtitle: string;
  orders: OrderProps[];
  prompt: string;
}

const orderTypeMapping: Record<string, string> = {
  "Stop Loss": "Stop Loss",
  "Take Profit": "Take Profit",
  "increase": "Increase",
  "decrease": "Decrease",
};

export const PerpsOrdersSelectOptionView = ({
  data,
  sendMessageHidden,
}: {
  data: SelectOptionStruct;
  sendMessageHidden: (message: string) => void;
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  function enactOnSelectedOption(orderIndex: string, user_prompt: string) {
    const updatedMessage = `${user_prompt}; order_id: ${orderIndex}.`;
    sendMessageHidden(updatedMessage);
    setSelectedOption("");
  }

  return (
    <>
      <div
        className={`bg-gray-800 border  shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4 min-w-full sm:min-w-[450px] ${"border-gray-700"}`}
      >
        {/* Title */}
        <div className="flex flex-col items-center gap-4">
          <h3 className="text-lg font-semibold">{data.title}</h3>
        </div>
        {/* Subtitle */}
        <div className="flex flex-col items-center gap-4">
          <p className="text-sm font-semibold">{data.subtitle}</p>
        </div>
        {/* Display Data */}
        {data.orders.map((order, i) => {
          if (order.orderId) {
            order.orderIndex = order.orderId;
          }
          return (
            <div
              key={i}
              className={`bg-gray-700 border border-gray-800 rounded-lg p-4 flex flex-col items-center gap-4 hover:bg-gray-600 cursor-pointer`}
              onClick={() => {
                if (
                  selectedOption !== order.orderIndex ||
                  selectedOption === ""
                ) {
                  setSelectedOption(order.orderIndex);
                  enactOnSelectedOption(order.orderIndex, data.prompt);
                }
              }}
            >
              <div className="flex flex-row items-center px-1 w-full">
                <span className="text-white text-center font-semibold text-sm leading-6 min-w-[300px]">
                  {order.orderType
                    ? orderTypeMapping[order.orderType] || ""
                    : ""}{" "}
                  {order.isLong ? "Long" : "Short"}{" "}
                  {order.purchaseTokenSymbol ? order.purchaseTokenSymbol : ""}
                  {" - Market "}
                  {order.assetTokenSymbol}
                </span>
              </div>
              <div className="flex flex-col items-center px-1 w-full">
                <span className="text-white font-semibold text-sm leading-6">
                  Position Size: ${Number(order.positionSize).toFixed(2)}
                </span>
                <span className="text-white font-semibold text-sm leading-6">
                  Trigger Price: {order.isLong ? "<" : ">"}$
                  {Number(order.limitPrice).toFixed(2)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
