export const SuccessIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2701_34537)">
      <path
        d="M8 0.806641C6.41775 0.806641 4.87103 1.27583 3.55544 2.15488C2.23985 3.03393 1.21447 4.28336 0.608967 5.74517C0.00346627 7.20698 -0.15496 8.81551 0.153721 10.3674C0.462403 11.9192 1.22433 13.3447 2.34315 14.4635C3.46197 15.5823 4.88743 16.3442 6.43928 16.6529C7.99113 16.9616 9.59966 16.8032 11.0615 16.1977C12.5233 15.5922 13.7727 14.5668 14.6518 13.2512C15.5308 11.9356 16 10.3889 16 8.80664C15.9977 6.68562 15.1541 4.65214 13.6543 3.15236C12.1545 1.65257 10.121 0.80897 8 0.806641ZM10.9656 7.77224L7.7656 10.9722C7.61558 11.1222 7.41213 11.2065 7.2 11.2065C6.98787 11.2065 6.78442 11.1222 6.6344 10.9722L5.0344 9.37224C4.88868 9.22136 4.80804 9.01927 4.80986 8.80952C4.81169 8.59976 4.89582 8.39911 5.04415 8.25078C5.19247 8.10246 5.39312 8.01832 5.60288 8.0165C5.81264 8.01468 6.01472 8.09531 6.1656 8.24104L7.2 9.27544L9.8344 6.64104C9.98528 6.49531 10.1874 6.41468 10.3971 6.4165C10.6069 6.41832 10.8075 6.50246 10.9559 6.65078C11.1042 6.79911 11.1883 6.99976 11.1901 7.20952C11.192 7.41928 11.1113 7.62136 10.9656 7.77224Z"
        fill="#31C48D"
      />
    </g>
    <defs>
      <clipPath id="clip0_2701_34537">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.806641)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const LoadingIcon = ({
  fill = "fill-blue-600",
  size = "w-6 h-6",
}: {
  fill?: string;
  size?: string;
}) => (
  <svg
    aria-hidden="true"
    className={`${size} text-gray-200 animate-spin dark:text-gray-600 ${fill}`}
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>
);

export const ExclamationIcon = ({
  fill = "fill-red-500",
}: {
  fill: string;
}) => (
  <svg
    className={`w-6 h-6 text-gray-800 dark:text-white ${fill}`}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 1616"
  >
    <path
      fillRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
      clipRule="evenodd"
      fill="currentFill"
    />
  </svg>
);

export const InfoIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16.3066C6.41775 16.3066 4.87103 15.8374 3.55544 14.9584C2.23985 14.0793 1.21447 12.8299 0.608967 11.3681C0.00346627 9.9063 -0.15496 8.29777 0.153721 6.74592C0.462403 5.19407 1.22433 3.76861 2.34315 2.64979C3.46197 1.53097 4.88743 0.769044 6.43928 0.460362C7.99113 0.15168 9.59966 0.310107 11.0615 0.915608C12.5233 1.52111 13.7727 2.54649 14.6518 3.86208C15.5308 5.17767 16 6.72439 16 8.30664C15.9977 10.4277 15.1541 12.4611 13.6543 13.9609C12.1545 15.4607 10.121 16.3043 8 16.3066ZM8 1.90664C6.7342 1.90664 5.49683 2.282 4.44435 2.98524C3.39188 3.68848 2.57157 4.68802 2.08717 5.85747C1.60277 7.02692 1.47603 8.31374 1.72298 9.55522C1.96992 10.7967 2.57946 11.9371 3.47452 12.8321C4.36958 13.7272 5.50995 14.3367 6.75142 14.5837C7.9929 14.8306 9.27973 14.7039 10.4492 14.2195C11.6186 13.7351 12.6182 12.9148 13.3214 11.8623C14.0246 10.8098 14.4 9.57244 14.4 8.30664C14.3981 6.60984 13.7232 4.98308 12.5234 3.78326C11.3236 2.58344 9.6968 1.90855 8 1.90664Z"
      fill="#9CA3AF"
    />
    <path
      d="M8 12.3066C7.78783 12.3066 7.58435 12.2224 7.43432 12.0723C7.28429 11.9223 7.2 11.7188 7.2 11.5066V8.30664H6.4C6.18783 8.30664 5.98435 8.22236 5.83432 8.07233C5.68429 7.9223 5.6 7.71882 5.6 7.50664C5.6 7.29447 5.68429 7.09099 5.83432 6.94096C5.98435 6.79093 6.18783 6.70664 6.4 6.70664H8C8.21217 6.70664 8.41566 6.79093 8.56569 6.94096C8.71572 7.09099 8.8 7.29447 8.8 7.50664V11.5066C8.8 11.7188 8.71572 11.9223 8.56569 12.0723C8.41566 12.2224 8.21217 12.3066 8 12.3066Z"
      fill="#9CA3AF"
    />
    <path
      d="M9.6 12.3066H6.4C6.18783 12.3066 5.98435 12.2224 5.83432 12.0723C5.68429 11.9223 5.6 11.7188 5.6 11.5066C5.6 11.2945 5.68429 11.091 5.83432 10.941C5.98435 10.7909 6.18783 10.7066 6.4 10.7066H9.6C9.81217 10.7066 10.0157 10.7909 10.1657 10.941C10.3157 11.091 10.4 11.2945 10.4 11.5066C10.4 11.7188 10.3157 11.9223 10.1657 12.0723C10.0157 12.2224 9.81217 12.3066 9.6 12.3066Z"
      fill="#9CA3AF"
    />
    <path
      d="M7.6 5.90664C8.26274 5.90664 8.8 5.36938 8.8 4.70664C8.8 4.0439 8.26274 3.50664 7.6 3.50664C6.93726 3.50664 6.4 4.0439 6.4 4.70664C6.4 5.36938 6.93726 5.90664 7.6 5.90664Z"
      fill="#9CA3AF"
    />
  </svg>
);

export const SwapIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7506 13.187H4.2666L5.88311 11.6478C6.37193 11.1823 6.37193 10.43 5.88311 9.96449C5.39428 9.49902 4.60416 9.49902 4.11533 9.96449L0.364745 13.5359C0.249727 13.6454 0.158462 13.7775 0.0947023 13.9227C-0.0315674 14.2144 -0.0315674 14.5418 0.0947023 14.8323C0.158462 14.9775 0.249727 15.1096 0.364745 15.2192L4.11533 18.7905C4.35912 19.0227 4.67917 19.1393 4.99922 19.1393C5.31927 19.1393 5.63932 19.0227 5.88311 18.7905C6.37193 18.3251 6.37193 17.5727 5.88311 17.1072L4.2666 15.568H13.7506C14.4407 15.568 15.0008 15.0346 15.0008 14.3775C15.0008 13.7204 14.4407 13.187 13.7506 13.187Z"
      fill="#9CA3AF"
    />
    <path
      d="M19.9053 6.78002C19.8415 6.63478 19.7503 6.50264 19.6353 6.39312L15.8847 2.82176C15.3958 2.35629 14.6057 2.35629 14.1169 2.82176C13.6281 3.28722 13.6281 4.03959 14.1169 4.50506L15.7334 6.04432H6.24941C5.55931 6.04432 4.99922 6.57764 4.99922 7.23477C4.99922 7.8919 5.55931 8.42523 6.24941 8.42523H15.7334L14.1169 9.96449C13.6281 10.43 13.6281 11.1823 14.1169 11.6478C14.3607 11.8799 14.6807 11.9966 15.0008 11.9966C15.3208 11.9966 15.6409 11.8799 15.8847 11.6478L19.6353 8.07642C19.7503 7.9669 19.8415 7.83476 19.9053 7.68953C20.0316 7.39906 20.0316 7.07049 19.9053 6.78002Z"
      fill="#9CA3AF"
    />
  </svg>
);

export const SwapRoute = () => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M12 12v4m0 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 0v2a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V8m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
);

export const SlippageIcon = () => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1"
      d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
    />
  </svg>
);

export const PercentIcon = () => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 21 21"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M8.891 15.107 15.11 8.89m-5.183-.52h.01m3.089 7.254h.01M14.08 3.902a2.849 2.849 0 0 0 2.176.902 2.845 2.845 0 0 1 2.94 2.94 2.849 2.849 0 0 0 .901 2.176 2.847 2.847 0 0 1 0 4.16 2.848 2.848 0 0 0-.901 2.175 2.843 2.843 0 0 1-2.94 2.94 2.848 2.848 0 0 0-2.176.902 2.847 2.847 0 0 1-4.16 0 2.85 2.85 0 0 0-2.176-.902 2.845 2.845 0 0 1-2.94-2.94 2.848 2.848 0 0 0-.901-2.176 2.848 2.848 0 0 1 0-4.16 2.849 2.849 0 0 0 .901-2.176 2.845 2.845 0 0 1 2.941-2.94 2.849 2.849 0 0 0 2.176-.901 2.847 2.847 0 0 1 4.159 0Z"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
);

export const OrderIcon = () => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.321 1.5L4 20h5M4 5l2-1v6m-2 0h4"
    />
  </svg>
);

export const PlusIcon = () => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5 12h14m-7 7V5"
    />
  </svg>
);
